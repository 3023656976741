import axiosApi from "./api_helper";
export const CardService = {
    getCard: async (payload) => { //MaDA
        return await axiosApi().post("api/poker/get-card",payload).then(res=>res.data);
    },
    getCardByMaVach: async (payload) => { //MaDA
        return await axiosApi().post("api/poker/get-card-by-mavach",payload).then(res=>res.data);
    },
    getKhachHangByID: async (payload) => { //MaDA
        return await axiosApi().post("api/poker/get-khachhang-byid",payload).then(res=>res.data);
    },
    addCard: async (payload) => { //MaDA
        return await axiosApi().post("api/poker/add-card",payload).then(res=>res.data);
    },
    addLichSuRaVao: async (payload) => { //MaDA
        return await axiosApi().post("api/poker/add-lichsu-ravao",payload).then(res=>res.data);
    },
    getLichSuRaVao: async (payload) => { //MaDA
        return await axiosApi().post("api/poker/get-lichsu-ravao",payload).then(res=>res.data);
    },
    updateCard: async (payload) => { //MaDA
        return await axiosApi().post("api/poker/update-card",payload).then(res=>res.data);
    },
    deleteCard: async (payload) => { //MaDA
        return await axiosApi().post("api/poker/delete-card",payload).then(res=>res.data);
    },
    getType: async () => { //MaDA
        return await axiosApi().post("api/poker/get-card-type").then(res=>res.data);
    },
    getCustomerGroup: async () => { //MaDA
        return await axiosApi().post("api/poker/get-customer-group").then(res=>res.data);
    },
    getCusByID: async (payload) => { //MaDA
        return await axiosApi().post("api/poker/customer/get-by-id",payload).then(res=>res.data);
    },
    filterCustomer: async (payload) => { //MaDA
        return await axiosApi().post("api/poker/get-customer-filter-card",payload).then(res=>res.data);
    },
}
