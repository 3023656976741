import React, { useEffect, useContext, useState } from "react";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import PortalLayout from "layouts/portal";
import OrderLayout from "layouts/orders";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { SignalrContextProvider } from "contexts/SignalrContext";
import Jackpot from "views/admin/Jackpot/Jackpot";
// import SignalrContext from "contexts/SignalrContext";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import './app.scss'
const App = () => {
  const [hubConnection, setHubConnection] = useState(null);
  // console.log(window.innerHeight);
  // console.log(window.innerWidth);
  // alert("height: " + window.outerHeight + ". width: "+window.innerWidth);
  // const { hubConnection } = useContext(SignalrContext);
  useEffect(() => {
    if (hubConnection) {
      hubConnection.on("OnOffScreen", async (response) => {
        console.log(response);
        if (response.deviceID === localStorage.getItem("deviceID")) {
          if (response.type) {
            let url = `/#/portal?magd=${
              response.maGD
            }&type=2&mact=${localStorage.getItem("MaCTDK")}`;
            const newWindow = window.open(url, "noopener,noreferrer");
            if (newWindow) newWindow.opener = null;
          } else {
            let url = `/#/admin/danh-sach-giai`;
            const newWindow = window.open(url, "noopener,noreferrer");
            if (newWindow) newWindow.opener = null;
          }
        }
      });
    }
  }, [hubConnection]);

  useEffect(() => {
    (async () => {
      try {
        let connection = new HubConnectionBuilder()
          .withUrl(localStorage.getItem("url") + "Poker")
          .withAutomaticReconnect()
          .configureLogging(LogLevel.Information)
          .build();
        await connection.start();
        setHubConnection(connection);
      } catch {}
    })();
    var id = "web" + Math.random().toString(16).slice(2);
    if (!localStorage.getItem("deviceID")) {
      localStorage.setItem("deviceID", id);
    }
  }, []);
  return (
    <ChakraProvider theme={theme}>
      <SignalrContextProvider>
        <React.StrictMode>
          <ThemeEditorProvider>
            <HashRouter>
              <Switch>
                <Route path={`/auth`} component={AuthLayout} />
                <Route path={`/admin`} component={AdminLayout} />
                <Route path={`/portal`} component={PortalLayout} />
                <Route path={`/jackpot`} component={Jackpot} />
                <Route path={`/orders`} component={OrderLayout} />
                <Redirect from="/" to="/admin/danh-sach-giai" />
              </Switch>
            </HashRouter>
          </ThemeEditorProvider>
        </React.StrictMode>
      </SignalrContextProvider>
    </ChakraProvider>
  );
};
export default App;
