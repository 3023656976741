import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import DebounceSelect from "components/DebounceSelect";
import { BuyinService } from "services/BuyinService";
import { JackpotService } from "services/JackpotService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    NgayChi: new Date(),
  };
  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {
    MaKH: null,
    SoTien: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    setErrors(defaultErrors);
    onAfterSubmit();
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    if (_data.MaKH?.length === 0) {
      setLoading(false);
      return;
    }
    if(Array.isArray(_data.MaKH)){
      _data.MaKH = _data.MaKH[0].value;
    }
    let _res = null;
    if (isupdate) {
      _res = await JackpotService.updatePhieuChi(_data);
    } else {
      _res = await JackpotService.addPhieuChi(_data);
      setData((pre) => {
        return { ...pre, ID: _res.data };
      });
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} phiếu chi thành công!`
      );
      // setIsUpdate(true);
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  async function fetchUserList(username) {
    return BuyinService.filterCustomer({ InputSearch: username }).then(
      (res) => {
        return res?.data?.map((item) => ({
          label: `${item.TenCongTy} - ${item.DiDong} - ${new Date(
            item.NgaySinh
          ).getDate()}/${new Date(item.NgaySinh).getMonth() + 1}/${new Date(
            item.NgaySinh
          ).getFullYear()}`,
          value: item.MaKH,
          TenKH: item.TenCongTy,
          DiDong: item.DiDong,
        }));
      }
    );
  }
  return (
    <Drawer
      title="PHIẾU CHI"
      width={620}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          {/* <Button
            loading={loading}
            onClick={() => onSubmit(false)}
            type="primary"
            ghost
          >
            Lưu
          </Button> */}
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[24, 12]}>
        <Col span={24} className="gutter-row">
          <p>
            Khách hàng <span style={{ color: "red" }}>*</span>
          </p>
          <DebounceSelect
            mode="multiple"
            value={data?.MaKH}
            placeholder="Tìm kiểm khách hàng"
            status={data?.MaKH ? "" : "error"}
            fetchOptions={fetchUserList}
            onChange={(newValue) => applyChange("MaKH", newValue)}
            style={{
              width: "100%",
            }}
          />
          <p style={{ color: "red" }}>{errors?.MaKH}</p>
        </Col>
        <Col span={24}>
          <p>Ngày chi</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={dayjs(data?.NgayChi)}
            locale={vi}
            onChange={(v) => applyChange("NgayChi", v)}
          />
        </Col>

        <Col span={24}>
          <p style={{ color: "red" }}>Số tiền</p>
          <InputNumber
            style={{ width: "100%" }}
            addonAfter="vnđ"
            value={data?.SoTien}
            status={data?.SoTien ? "" : "error"}
            onChange={(v) => applyChange("SoTien", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
          <p style={{ color: "red" }}>{errors?.SoTien}</p>
        </Col>

        <Col span={24}>
          <p>Lý do</p>
          <TextArea
            value={data?.LyDo}
            onChange={(e) => applyChange("LyDo", e.target.value)}
            placeholder="Nhập lý do"
          />
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
