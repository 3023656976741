import { Col, Row } from "antd";
import { Format_Currency } from "layouts/ultis/common";
import * as React from "react";

const BuyinToPrint = (props, ref) => {
  const { data, company , soTT } = props;
  const getDate = () => {
    return (
      <p style={{ textAlign: "center", fontWeight: "600", margin: "15px 0px" }}>
        Ngày {new Date().getDate()} tháng {new Date().getMonth() + 1} năm{" "}
        {new Date().getFullYear()} | {new Date().getHours()}:
        {new Date().getMinutes()}
      </p>
    );
  };
  return (
    <div ref={ref} style={{ margin: "0px 5px 5px" }}>
      <div style={{ padding: "10px 0px" }}>
        {company.Logo && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 15,
            }}
          >
            <img
              width={150}
              height={150}
              style={{ textAlign: "center" }}
              src={company.Logo}
            />
          </div>
        )}
        <p
          style={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}
        >
          {data?.TenGD?.toUpperCase()}
        </p>
        {getDate()}
        <Row>
          <Col span={6}>
            <p style={{ fontWeight: "bold", fontSize: 18 }}>Name</p>
          </Col>
          <Col span={18}>
            <p style={{ fontWeight: "bold", fontSize: 18 }}>
              {data?.TenCongTy}
              {/* {data?.MaKH?.length > 0 && data?.MaKH[0].label.split("-")[0]} */}
            </p>
          </Col>
          <Col span={6}>Tel</Col>
          <Col span={18}>
            {data?.DiDong}
            {/* <p>{data?.MaKH?.length > 0 && data?.MaKH[0].label.split("-")[1]}</p> */}
          </Col>

          <Col span={6}>CardNo</Col>
          <Col span={18}>
            <p>{data?.SoThe}</p>
          </Col>

          <Col span={6}>Payment</Col>
          <Col span={18}>
            <p>{data?.TenHT}</p>
          </Col>

          <Col span={6}>
            <p style={{ fontWeight: "bold", fontSize: 18 }}>Entry fee</p>
          </Col>
          <Col span={18}>
            <p style={{ fontWeight: "bold", fontSize: 18 }}>
              {Format_Currency(data?.TongTien)}
            </p>
          </Col>
        </Row>
        <p
          style={{ fontSize: "50px", fontWeight: "bold", textAlign: "center" }}
        >
          {soTT}
        </p>
        <p style={{ textAlign: "center", fontStyle: "italic" }}>
          Tôi tự nguyện tài trợ cho giải đấu {company.TenCT} và tuân thủ theo
          các quy định của đơn vị tổ chức
        </p>
      </div>
    </div>
  );
};
export default React.forwardRef(BuyinToPrint);
