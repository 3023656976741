import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { HomeService } from "services/HomeService";
import {
  Button,
  Col,
  Input,
  Modal,
  Popconfirm,
  Row,
  Table,
  Tooltip,
  notification,
} from "antd";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { TourService } from "services/TourService";
import _ from "lodash";
import "./scss/index.scss";
import FormAdd from "./FormAdd";
import Toolbar from "components/Toolbar";
import { OtherService } from "services/OtherService";
import { Format_Date } from "layouts/ultis/common";
export default function Index() {
  const [api, contextHolder] = notification.useNotification();
  const [detail, setDetail] = useState({});
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const refDetail = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useEffect(() => {
    filter();
  }, []);
  const filter = async (_date) => {
    setLoading(true);
    let _res = await OtherService.getDevice();
    if (_res.data) setData(_res.data);
    setLoading(false);
  };

  const onAfterSubmit = () => {
    filter();
  };
  const columns = [
    {
      title: "Device ID",
      dataIndex: "DeviceID",
    },
    {
      title: "Tên màn hình",
      dataIndex: "DeviceName",
    },
    {
      title: "Giải đang bật",
      dataIndex: "TenGD",
    },
    {
      title: "Ngày tạo",
      dataIndex: "NgayNhap",
      render: (item) => Format_Date(item)
    },
    {
      title: "",
      fixed: "right",
      width: "160px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => {
                  setDetail(item);
                  showModal();
                }}
              />
            </Tooltip>
            <Popconfirm
              title="Xoá màn hình"
              description="Bạn có chắc chắn muốn xoá màn hình?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await OtherService.deleteDevice({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá màn hình thành công!");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    let _res = await OtherService.updateDevice(detail);
    if(_res.status === 2000){
      onAfterSubmit();
    }
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onChangeName=(v)=>{
    let _detail = _.cloneDeep(detail);
    _detail.DeviceName = v;
    setDetail(_detail);
  }
  return (
    <Box pt={{ base: "130px", md: "60px", xl: "60px" }}>
      <Table
        style={{ height: 100 }}
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 250,
          // x: 2500
        }}
        size="small"
      />
      {contextHolder}
      <Modal
        title="Màn hình"
        open={isModalOpen}
        onOk={handleOk}
        okText="Lưu"
        cancelText="Huỷ"
        onCancel={handleCancel}
      >
        {/* <p>Tên màn hình</p> */}
        <Input
          value={detail?.DeviceName}
          placeholder="Nhập tên màn hình"
          onChange={(e) => onChangeName(e.target.value)}
        />
      </Modal>
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
    </Box>
  );
}
