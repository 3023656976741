import { Box } from "@chakra-ui/react";
import React, { useEffect,useState } from "react";
import {  Table,QRCode,Button,Modal,Input,Popconfirm } from 'antd';
import { OrderService } from "services/OrdersService";

export default function Settings() {
  const [data,setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [tabelName,setTableName] = useState("")

  useEffect(()=>{
    filter();
  },[])
  const filter =async () =>{
    let _res = await OrderService.getTable({});
    if(Array.isArray(_res.data)) setData(_res.data);
  }
  const columns = [
    {
      title: 'Tên bàn',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: '',
      key: 'QRCode',
      render: (item) =>{
        return <div id={`myqrcode_${item.ID}`} style={{display:'none'}}>
          <QRCode size={200} value={`${item.QRCode}&table=${item.ID}` || '-'} bordered={true} />
        </div>
      }
    },
    {
      title: '',
      render: (item) =>{
        return <div>
           <Button type="primary" ghost  onClick={()=>downloadQRCode(`myqrcode_${item.ID}`)}>
            Tải QR
          </Button>
          <Popconfirm
            title="Xoá bàn"
            description="Bạn có chắc chắn muốn xoá bàn?"
            okText="Đồng ý"
            cancelText="Không"
            onConfirm={()=>removeTable(item.ID)}
          >
            <Button style={{marginLeft:10}} danger ghost>Xoá</Button>
          </Popconfirm>
        </div>
      }
    },
  ];
  const removeTable = async (value) =>{
     await OrderService.removeTable({ID: Number(value)});
    filter();
  }
  const downloadQRCode = (id) => {
    const canvas = document.getElementById(id)?.querySelector('canvas');
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement('a');
      a.download = 'QRCode.png';
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  const showModal = () => {
    setOpen(true);
  };
  const handleOk =async () => {
    let _res = await OrderService.addTable({
      Name: tabelName,
      QRCode: 'https://'+window.location.hostname +'/#/orders?mact='+localStorage.getItem('MaCTDK')
    })
    if(_res.status === 2000){
      filter();
      setOpen(false);
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <Box pt={{ base: "130px", md: "60px", xl: "60px" }}>
      <Button type="primary" style={{marginBottom:10}} onClick={showModal}>
            Thêm bàn
      </Button>
      <Table dataSource={data} columns={columns} />
      <Modal
        open={open}
        title="Thêm bàn"
        onOk={handleOk}
        okText='Lưu'
        cancelText='Huỷ'
        onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <Input placeholder="Nhập tên bàn" onChange={(e)=>setTableName(e.target.value)}/>
      </Modal>
    </Box>
  );
}
