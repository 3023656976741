import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { HomeService } from "services/HomeService";
import { Button, Popconfirm, Table, Tooltip, notification } from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { JackpotService } from "services/JackpotService";
import { BuyinService } from "services/BuyinService";
export default function Index() {
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    ListMaCT: ",,",
  });
  const refDetail = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    let _resCT = await BuyinService.getCompany();
    setCompanies(_resCT.data);
  };
  useEffect(() => {
    filter(filterCondition);
    loadCategories();
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await JackpotService.getPhieuThu(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const columns = [
    {
      title: "Ngày thu",
      dataIndex: "NgayThu",
      render: (item) => Format_Date(item),
    },
    {
      title: "Số tiền",
      dataIndex: "SoTien",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Lý do",
      dataIndex: "LyDo",
    },
    {
      title: "Người nhập",
      dataIndex: "NguoiNhap",
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
      render: (item) => Format_Date(item),
    },
    {
      title: "",
      fixed: "right",
      width: "100px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá phiếu thu"
              description="Bạn có chắc chắn muốn xoá phiếu thu?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await JackpotService.deletePhieuThu({ ID: Number(value) });
    if(_res.status === 2000){
      let _data = _.cloneDeep(data);
      let fil = _data.filter(v=>v.ID !==Number(value) );
      setData(fil);
      openNotification(
        "success",
        "topRight",
        "Xoá phiếu thu thành công"
      );
    }else{
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <Box pt={{ base: "130px", md: "60px", xl: "60px" }}>
      <Toolbar
        left={() => (
          <Button type="primary" onClick={() => refDetail.current.show()}>
            Thêm
          </Button>
        )}
        right={() => (
          <FilterModal
            filterCondition={filterCondition}
            onChangeFilter={onChangeFilter}
            companies={companies}
          />
        )}
      />
      <Table
        style={{ height: 100 }}
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 250,
          // x: 2500
        }}
        size="small"
      />
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Box>
  );
}
