import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Popconfirm, notification } from "antd";
import { OrderService } from "services/OrdersService";
import { BuyinService } from "services/BuyinService";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import Toolbar from "components/Toolbar";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { UploadOutlined } from "@ant-design/icons";
import { IoMdPrint } from "react-icons/io";
import BuyinToPrint from "components/BuyinToPrint";
import { useReactToPrint } from "react-to-print";

export default function Settings() {
  const [data, setData] = useState([]);
  const [dataPrint,setDataPrint] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const refPrint = useRef(null);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    ListMaCT: ",,",
    InputSearch: "",
  });
  useEffect(() => {
    loadCategories();
    loadBuyin(filterCondition);
  }, []);

  const loadBuyin = async (_filter) => {
    setLoading(true);
    let _res = await BuyinService.getBuyin(_filter);
    setData(_res.data);
    setLoading(false);
  };
  const loadCategories = async () => {
    let _resCT = await BuyinService.getCompany();
    setCompanies(_resCT.data);
  };
  const columns = [
    {
      title: "Số hoá đơn",
      render: (item) => {
        return (
          <p
          //  onClick={()=>refDetail.current.update(item)}
          >
            {item.SoHoaDon}
          </p>
        );
      },
    },
    {
      title: "Tour",
      dataIndex: "TenGD",
      width: "400px",
    },
    {
      title: "Loại thẻ",
      dataIndex: "TenLT",
    },
    {
      title: "Mã thẻ",
      dataIndex: "SoThe",
    },
    {
      title: "Tên khách hàng",
      dataIndex: "TenCongTy",
      width: "200px",
    },
    {
      title: "Tiền giải",
      dataIndex: "TienGiai",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tên giải hiện tại",
      dataIndex: "TenGDEnd",
      width: "400px",
    },
    {
      title: "Tiền phí",
      dataIndex: "TienPhi",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tiền bounty",
      dataIndex: "TienBounty",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tiền voucher",
      dataIndex: "TienVouCher",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tổng tiền",
      dataIndex: "TongTien",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tỷ lệ CK",
      dataIndex: "TyLeCK",
    },
    {
      title: "Tiền CK",
      dataIndex: "TienCK",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Thực thu",
      dataIndex: "ThucThu",
      render: (item) => Format_Currency(item),
    },
    {
      title: "",
      fixed: "right",
      width: "90px",
      render: (item) => {
        return (
          <div>
            <Button
              type="primary"
              style={{ marginRight: 5 }}
              ghost
              icon={<IoMdPrint />}
              onClick={()=>{
                setDataPrint(item);
              }}
            />
            <Popconfirm
              title="Xoá bàn"
              description="Bạn có chắc chắn muốn xoá buyin hay không?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item)}
            >
              <Button danger ghost icon={<CiTrash />} />
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  useEffect(()=>{
    if(dataPrint) handlePrint();
  },[dataPrint])
   const handlePrint = useReactToPrint({
     content: () => refPrint.current,
   });
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const remove = async (item) => {
    let _res = await BuyinService.deleteBuyin({
      MaGD: Number(item.MaGD),
      ID: item.ID,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(item.ID));
      setData(fil);
      openNotification("success", "topRight", "Xoá buyin thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const refDetail = useRef(null);
  const onAfterSubmit = () => {
    loadBuyin(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    loadBuyin(_filter);
    setFilterCondition(_filter);
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách buyin" + fileExtension);
  };
  return (
    <Box pt={{ base: "130px", md: "50px", xl: "50px" }}>
      <Toolbar
        left={() => (
          <>
            <Button type="primary" onClick={() => refDetail.current.show()}>
              Thêm
            </Button>
            <Button
              icon={<UploadOutlined />}
              onClick={() => exportToCSV()}
              style={{ marginLeft: 10 }}
            >
              Export
            </Button>
          </>
        )}
        right={() => (
          <FilterModal
            companies={companies}
            filterCondition={filterCondition}
            onChangeFilter={onChangeFilter}
          />
        )}
      />

      <Table
        style={{ height: 100 }}
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 250,
          x: 2500,
        }}
        size="small"
        summary={(pageData) => {
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Tổng</Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  <p style={{ fontWeight: "bold" }}>
                    {Format_Currency(
                      pageData?.reduce(
                        (pre, current) => (pre += current.TienGiai),
                        0
                      )
                    )}
                  </p>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                  <p style={{ fontWeight: "bold" }}>
                    {Format_Currency(
                      pageData?.reduce(
                        (pre, current) => (pre += current.TienPhi),
                        0
                      )
                    )}
                  </p>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8}>
                  <p style={{ fontWeight: "bold" }}>
                    {Format_Currency(
                      pageData?.reduce(
                        (pre, current) => (pre += current.TienBounty),
                        0
                      )
                    )}
                  </p>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9}>
                  <p style={{ fontWeight: "bold" }}>
                    {Format_Currency(
                      pageData?.reduce(
                        (pre, current) => (pre += current.TienVouCher),
                        0
                      )
                    )}
                  </p>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10}>
                  <p style={{ fontWeight: "bold" }}>
                    {Format_Currency(
                      pageData?.reduce(
                        (pre, current) => (pre += current.TongTien),
                        0
                      )
                    )}
                  </p>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={11} />
                <Table.Summary.Cell index={12} />
                <Table.Summary.Cell index={13}>
                  <p style={{ fontWeight: "bold" }}>
                    {Format_Currency(
                      pageData?.reduce(
                        (pre, current) => (pre += current.ThucThu),
                        0
                      )
                    )}
                  </p>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      <div style={{ display: "none" }}>
        <BuyinToPrint
          ref={refPrint}
          data={dataPrint}
          company={companies?.length > 0 && companies[0]}
          soTT={dataPrint?.SoTT}
        />
      </div>
      {contextHolder}
    </Box>
  );
}
