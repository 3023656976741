import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useContext,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Divider,
  Checkbox,
  Table,
  Tabs,
  Form,
  Popconfirm,
  Upload,
  message,
  Image,
} from "antd";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { TourService } from "services/TourService";
import { CiTrash } from "react-icons/ci";
import { FileUpload } from "primereact/fileupload";
import { HomeService } from "services/HomeService";
const { TextArea } = Input;
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    NgayNhap: new Date(),
    isHienThiBuyIn: true,
    isTichDiem: false,
    isTourMau: false,
  };
  const [files, setFiles] = useState([]);

  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tourMau, setTourMau] = useState([]);
  const [coCau, setCoCau] = useState([]);
  const defaultErrors = {
    TenGD: null,
    PhanTramTraoGiai: null,
    TienGiaiBuyIn: null,
    SoChipBuyin: null,
    GTD: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const getCoCauGiaiThuong = async (id) => {
    let _res = await TourService.getCoCauGiaiThuong({ ID: id });
    setCoCau(_res?.data ?? []);
  };
  const loadCategories = async () => {
    let _res = await TourService.getGiaiDauMau();
    setTourMau(_res?.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      loadCategories();
    },
    update: async (data) => {
      setOpen(true);
      loadCategories();
      setIsUpdate(true);
      let _res = await TourService.getDetail({ ID: data.id });
      getCoCauGiaiThuong(data.id);
      if (_res.status === 2000 && _res?.data?.length > 0)
        setData(_res?.data[0]);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    onAfterSubmit();
    setCoCau([]);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaGDM":
        let gd = tourMau.find((t) => t.ID === v);
        _data = {
          ..._data,
          ...gd,
          ...defaulData,
          TienCongThem: 0,
        };
        console.log(_data);
        break;
      case "PhanTramJackpot":
      case "ptService":
        _data[p] = v;
        _data.PhanTramTraoGiai =
          100 - (_data.PhanTramJackpot ?? 0) - (_data.ptService ?? 0);
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    _data.ListGiaiThuong = coCau;
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    if (files?.length > 0) {
      var formData = new FormData();
      formData.append("Image", files[0]);
      formData.append("TenCTDK", localStorage.getItem("MaCTDK"));
      let _resupload = await HomeService.upload(formData);
      if (_resupload.status === 2000) {
        _data.UrlImage = localStorage.getItem("url") + _resupload.data;
        setData((pre) => {
          return { ...pre, UrlImage: _data.UrlImage };
        });
      }
    }
    let _res = null;
    if (isupdate) {
      _res = await TourService.updateGiaiDau(_data);
    } else {
      _res = await TourService.addGiaiDau(_data);
      if (_res.status === 2000) {
        let _resBlind = await TourService.getBlind({ MaGD: _data.ID });
        if (Array.isArray(_resBlind.data) && _resBlind.data?.length > 0) {
          let payload = {
            ListBlind: _resBlind.data,
            MaGD: _res.daTa,
          };
          await TourService.addBlind(payload);
        }
      }
      setData((pre) => {
        return { ...pre, ID: _res.daTa, UrlImage: _data.UrlImage };
      });
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} giải đấu thành công!`
      );
      setIsUpdate(true);
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onBeforeUpload = (v) => {
    setFiles(v.files);
  };

  const renderInfo = () => {
    return (
      <>
        <Row gutter={[24, 12]}>
          <Col span={8} className="gutter-row">
            <p>Giải đấu mẫu</p>
            <Select
              style={{
                width: "100%",
              }}
              showSearch
              filterOption={(input, option) =>
                (option?.TenGD?.toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              value={data?.MaGDM}
              onChange={(v) => applyChange("MaGDM", v)}
              options={tourMau}
              placeholder="Chọn giải đấu mẫu"
              fieldNames={{ label: "TenGD", value: "ID" }}
            />
          </Col>
          <Col span={16}>
            <p>
              Tên giải <span style={{ color: "red" }}>*</span>
            </p>
            <Input
              status={data?.TenGD?.length > 0 ? "" : "error"}
              value={data?.TenGD}
              placeholder="Nhập tên giải đấu"
              onChange={(e) => applyChange("TenGD", e.target.value)}
            />
            <p style={{ color: "red" }}>{errors?.TenGD}</p>
          </Col>
          <Col span={12}>
            <p>Ghi chú 1</p>
            <TextArea
              value={data?.GhiChu}
              onChange={(e) => applyChange("GhiChu", e.target.value)}
              placeholder="Nhập ghi chú 1"
            />
          </Col>
          <Col span={12}>
            <p>Ghi chú 2</p>
            <TextArea
              value={data?.GhiChu2}
              onChange={(e) => applyChange("GhiChu2", e.target.value)}
              placeholder="Nhập ghi chú 2"
            />
          </Col>

          <Col span={6}>
            <p>
              Entry Fee <span style={{ color: "red" }}>*</span>
            </p>
            <InputNumber
              style={{ width: "100%" }}
              addonAfter="%"
              max={100}
              status={data?.PhanTramTraoGiai > 0 ? "" : "error"}
              value={data?.PhanTramTraoGiai}
              onChange={(v) => applyChange("PhanTramTraoGiai", v)}
              // formatter={(value) =>
              //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "")
              // }
            />
            <p style={{ color: "red" }}>{errors?.PhanTramTraoGiai}</p>
          </Col>
          <Col span={6}>
            <p>PT Jackpot</p>
            <InputNumber
              style={{ width: "100%" }}
              addonAfter="%"
              max={100}
              value={data?.PhanTramJackpot}
              onChange={(v) => applyChange("PhanTramJackpot", v)}
              // formatter={(value) =>
              //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              // }
            />
          </Col>
          <Col span={6}>
            <p>Service charge</p>
            <InputNumber
              style={{ width: "100%" }}
              addonAfter="%"
              max={100}
              value={data?.ptService}
              onChange={(v) => applyChange("ptService", v)}
              // formatter={(value) =>
              //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              // }
            />
          </Col>
          <Col span={6}>
            <p>Tiền High Hand</p>
            <InputNumber
              style={{ width: "100%" }}
              addonAfter="vnđ"
              value={data?.TienHighHand}
              onChange={(v) => applyChange("TienHighHand", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Col>
        </Row>
        <Row gutter={[24, 12]}>
          <Divider
            orientation="left"
            style={{ margin: "25px 0px 0px", fontSize: 14 }}
          >
            Thông tin khác
          </Divider>
          <Col span={2}>
            <p style={{ marginTop: 25, fontWeight: "bold" }}>Buyin:</p>
            <p style={{ marginTop: 15, fontWeight: "bold" }}>Addon:</p>
          </Col>
          <Col span={6}>
            <p>
              Phí buyin <span style={{ color: "red" }}>*</span>
            </p>
            <InputNumber
              style={{ width: "100%" }}
              addonAfter="vnđ"
              value={data?.TienGiaiBuyIn}
              status={data?.TienGiaiBuyIn > 0 ? "" : "error"}
              onChange={(v) => applyChange("TienGiaiBuyIn", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
            <p style={{ color: "red" }}>{errors?.TienGiaiBuyIn}</p>
            <InputNumber
              style={{ width: "100%", marginTop: 5 }}
              addonAfter="vnđ"
              value={data?.TienGiaiAddon}
              onChange={(v) => applyChange("TienGiaiAddon", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Col>
          <Col span={5}>
            <p>Tiền phí</p>
            <InputNumber
              style={{ width: "100%" }}
              addonAfter="vnđ"
              value={data?.GiaBuyin}
              onChange={(v) => applyChange("GiaBuyin", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
            <InputNumber
              style={{ width: "100%", marginTop: 5 }}
              addonAfter="vnđ"
              value={data?.GiaAddon}
              onChange={(v) => applyChange("GiaAddon", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Col>
          <Col span={5}>
            <p>Tiền bounty</p>
            <InputNumber
              style={{ width: "100%" }}
              addonAfter="vnđ"
              value={data?.TienBountyBuyIn}
              onChange={(v) => applyChange("TienBountyBuyIn", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
            <InputNumber
              style={{ width: "100%", marginTop: 5 }}
              addonAfter="vnđ"
              value={data?.TienBountyAddon}
              onChange={(v) => applyChange("TienBountyAddon", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Col>
          <Col span={3}>
            <p>
              Số chip <span style={{ color: "red" }}>*</span>
            </p>
            <InputNumber
              value={data?.SoChipBuyin}
              onChange={(v) => applyChange("SoChipBuyin", v)}
              style={{ width: "100%" }}
              status={data?.SoChipBuyin > 0 ? "" : "error"}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
            <p style={{ color: "red" }}>{errors?.SoChipBuyin}</p>
            <InputNumber
              style={{ width: "100%", marginTop: 5 }}
              value={data?.SoChipAddon}
              onChange={(v) => applyChange("SoChipAddon", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Col>
          <Col span={3}>
            <p>Mua tối đa</p>
            <InputNumber
              style={{ width: "100%" }}
              defaultValue={1}
              value={data?.SoLanMuaToiDaBuyin}
              onChange={(v) => applyChange("SoLanMuaToiDaBuyin", v)}
            />
            <InputNumber
              style={{ width: "100%", marginTop: 5 }}
              defaultValue={50}
              value={data?.SoLanMuaToiDaAddon}
              onChange={(v) => applyChange("SoLanMuaToiDaAddon", v)}
            />
          </Col>

          <Col span={6}>
            <p>Ngày bắt đầu</p>
            <DatePicker
              allowClear={false}
              format="DD-MM-YYYY"
              style={{ width: "100%" }}
              value={dayjs(data?.NgayNhap)}
              // placeholder="Ngày hiệu lực"
              locale={vi}
              onChange={(v) => applyChange("NgayNhap", v)}
            />
          </Col>
          <Col span={6}>
            <p>Tiền cộng thêm</p>
            <InputNumber
              style={{ width: "100%" }}
              addonAfter="vnđ"
              value={data?.TienCongThem}
              onChange={(v) => applyChange("TienCongThem", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Col>
          <Col span={6}>
            <p>Starting chips</p>
            <Input
              value={data?.StartingChips}
              placeholder="Nhập starting chips"
              onChange={(e) => applyChange("StartingChips", e.target.value)}
            />
          </Col>
          <Col span={6}>
            <p>Chip cộng thêm</p>
            <InputNumber
              style={{ width: "100%" }}
              value={data?.ChipBonus}
              onChange={(v) => applyChange("ChipBonus", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Col>
          <Col span={6}>
            <p>
              GTD <span style={{ color: "red" }}>*</span>
            </p>
            <InputNumber
              style={{ width: "100%" }}
              addonAfter="vnđ"
              value={data?.GTD}
              status={data?.GTD > 0 ? "" : "error"}
              onChange={(v) => applyChange("GTD", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
            <p style={{ color: "red" }}>{errors?.GTD}</p>
          </Col>
          <Col span={4}>
            <p>Hiển thị thu ngân</p>
            <Checkbox
              checked={data?.isHienThiBuyIn === true}
              onChange={() => applyChange("isHienThiBuyIn", true)}
            >
              Có
            </Checkbox>
            <Checkbox
              checked={data?.isHienThiBuyIn === false}
              onChange={() => applyChange("isHienThiBuyIn", false)}
            >
              Không
            </Checkbox>
          </Col>
          <Col span={4}>
            <p>Tích điểm</p>
            <Checkbox
              checked={data?.isTichDiem === true}
              onChange={() => applyChange("isTichDiem", true)}
            >
              Có
            </Checkbox>
            <Checkbox
              checked={data?.isTichDiem === false}
              onChange={() => applyChange("isTichDiem", false)}
            >
              Không
            </Checkbox>
          </Col>
          <Col span={4}>
            <p>Tour mẫu</p>
            <Checkbox
              checked={data?.isTourMau === true}
              onChange={() => applyChange("isTourMau", true)}
            >
              Có
            </Checkbox>
            <Checkbox
              checked={data?.isTourMau === false}
              onChange={() => applyChange("isTourMau", false)}
            >
              Không
            </Checkbox>
          </Col>
          <Col span={8}>
            {data.UrlImage && (
               <Image  src={data.UrlImage} style={{ width: "100%" }}/>
            )}
            <FileUpload
              mode="basic"
              name="demo[]"
              url="/api/upload"
              accept="image/*"
              maxFileSize={1000000}
              onSelect={(e) => onBeforeUpload(e)}
              chooseLabel="Tải background"
            />
          </Col>
        </Row>
      </>
    );
  };

  const renderCoCauGiai = () => {
    const addCoCau = () => {
      let _cocau = _.cloneDeep(coCau);
      _cocau.push({
        isHienThi: true,
        TenGiai: "",
        HienThi: "",
      });
      setCoCau(_cocau);
    };
    const onChangeCoCau = (p, v, index) => {
      let _cocau = _.cloneDeep(coCau);
      let item = _cocau.slice(index, index + 1);
      if (item?.length > 0) {
        _cocau.splice(index, 1, {
          ...item[0],
          [p]: v,
        });
      }

      setCoCau(_cocau);
    };
    const deleteCoCau = (v, index) => {
      let _cocau = _.cloneDeep(coCau);
      _cocau.splice(index, 1);
      setCoCau(_cocau);
      if (v.ID) {
        TourService.deleteCoCauGiaiThuong({ ID: v.ID });
      }
    };
    return (
      <>
        <Button
          loading={loading}
          onClick={() => addCoCau()}
          type="primary"
          ghost
          style={{ marginBottom: 10 }}
        >
          Thêm
        </Button>
        <DataTable
          size="small"
          showGridlines
          stripedRows
          value={coCau}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            header="Bỏ hiển thị"
            style={{ width: "100px" }}
            body={(item, row) => {
              return (
                <Checkbox
                  checked={item.isHienThi}
                  onChange={() =>
                    onChangeCoCau("isHienThi", !item.isHienThi, row.rowIndex)
                  }
                />
              );
            }}
          />
          <Column
            header="Tên giải"
            body={(item, row) => {
              return (
                <Input
                  value={item?.TenGiai}
                  placeholder="Nhập tên giải"
                  onChange={(e) =>
                    onChangeCoCau("TenGiai", e.target.value, row.rowIndex)
                  }
                />
              );
            }}
          />
          <Column
            header="Hiển thị"
            body={(item, row) => {
              return (
                <Input
                  value={item?.HienThi}
                  placeholder="Nhập hiển thị"
                  onChange={(e) =>
                    onChangeCoCau("HienThi", e.target.value, row.rowIndex)
                  }
                />
              );
            }}
          />
          <Column
            body={(item, row) => {
              return (
                <Button
                  onClick={() => deleteCoCau(item, row.rowIndex)}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              );
            }}
          />
        </DataTable>
      </>
    );
  };
  const items = [
    {
      key: "1",
      label: "Thông tin chung",
      children: renderInfo(),
    },
    {
      key: "2",
      label: "Cơ cấu giải thưởng",
      children: renderCoCauGiai(),
    },
  ];

  return (
    <Drawer
      title="THÊM GIẢI"
      width={1120}
      onClose={onClose}
      open={open}
      className="giai-dau-detail"
      styles={{
        body: {
          // paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(false)}
            type="primary"
            ghost
          >
            Lưu
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Tabs defaultActiveKey="1" items={items} />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
