import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Space,
  Drawer,
  notification,
  Popconfirm,
  UploadProps,
  Upload,
  Table,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import _ from "lodash";
import { TourService } from "services/TourService";
import { Format_Currency } from "layouts/ultis/common";
const { TextArea } = Input;
const SettingBlind = (props, ref) => {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [newFile, setNewFile] = useState(false);
  const [maGD, setMaGD] = useState(null);
  const loadCategories = async (magd) => {
    let _res = await TourService.getBlind({ MaGD: magd });
    setData(_res?.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async (magd) => {
      setOpen(true);
      setMaGD(magd);
      loadCategories(magd);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setNewFile(false);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let payload = {
      ListBlind: data,
      MaGD: maGD,
    };
    if (data?.length === 0) {
      setLoading(false);
      return;
    }
    let _res = await TourService.addBlind(payload);
    setLoading(false);
    if (_res.status === 2000) {
      setNewFile(false);
      openNotification("success", "topRight", `Cập nhật blind thành công!`);
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const removeBlind = async () => {
    setLoading(true);
    let _res = await TourService.removeBlind({ MaGD: maGD });
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Xoá blind thành công!`);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
    setLoading(false);
  };
  const loadDataImport = (data) => {
    let res = [];
    data?.map((item, i) => {
      if (i > 0) {
        res.push({
          STT: i + 1,
          TenLevel: item[0],
          Duration: item[1],
          Small: item[2],
          Big: item[3],
          Ante: item[4],
          isBreak: item[5]?.toString().toLowerCase() === 'true' ? true : false,
        });
      }
    });
    setData(res);
  };
  const columns = [
    {
      title: "Level",
      // dataIndex: "TenLevel",
      render: (item) => (
        <p
          style={{
            fontWeight: item.isBreak ? "900" : "500",
            color: item.isBreak ? "red" : "black",
          }}
        >
          {item?.TenLevel}
        </p>
      ),
    },
    {
      title: "Duration (m)",
      dataIndex: "Duration",
    },
    {
      title: "Small",
      dataIndex: "Small",
      render: (item) => Format_Currency(item),
    },
    {
      title: "BIG",
      dataIndex: "Big",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Ante",
      dataIndex: "Ante",
      render: (item) => Format_Currency(item),
    },
  ];
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    let _data = [];
    data?.map((item) => {
      _data.push({
        TenLevel: item.TenLevel,
        Duration: item.Duration,
        Small: item.Small,
        Big: item.Big,
        Ante: item.Ante,
        isBreak: item.isBreak ,
      });
    });
    if (_data?.length === 0) {
      _data = [
        {
          TenLevel: "Level 1",
          Duration: 25,
          Small: 100,
          Big: 100,
          Ante: 0,
          isBreak: false,
        },
        {
          TenLevel: "Break",
          Duration: 0,
          Small: 0,
          Big: 0,
          Ante: 0,
          isBreak: true,
        },
      ];
    }
    const ws = XLSX.utils.json_to_sheet(_data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Blind templete" + fileExtension);
  };
  return (
    <Drawer
      title="Cài đặt blind"
      width={1120}
      onClose={onClose}
      open={open}
      className="giai-dau-detail"
      styles={{
        body: {
          // paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Upload
            accept=".txt, .csv, .xlsx, .xls"
            showUploadList={false}
            beforeUpload={(file) => {
              const reader = new FileReader();
              const rABS = !!reader.readAsBinaryString;
              reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                loadDataImport(data);
                setNewFile(true);
              };
              if (rABS) reader.readAsBinaryString(file);
              else reader.readAsArrayBuffer(file);
            }}
          >
            <Button icon={<UploadOutlined />}>Upload File</Button>
          </Upload>
          <Button icon={<UploadOutlined />} onClick={() => exportToCSV()}>
            Export
          </Button>
          <Button
            loading={loading}
            onClick={() => {
              setData([]);
              removeBlind();
            }}
            type="primary"
            danger
            ghost
          >
            Xoá tất cả
          </Button>
          {newFile && (
            <Button
              loading={loading}
              onClick={() => onSubmit(true)}
              type="primary"
            >
              Lưu & Đóng
            </Button>
          )}
        </Space>
      }
    >
      {contextHolder}
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        pagination={false}
        scroll={{
          y: window.innerHeight - 150,
        }}
        size="small"
      />
    </Drawer>
  );
};
export default forwardRef(SettingBlind);
