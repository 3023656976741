import axios from "axios";
import { post } from "./api_helper";
import axiosApi from "./api_helper";
export const BuyinService = {
    getCompany: async () => { 
        return await axiosApi().get("api/poker/get-company").then(res=>res.data);
    },
    getCompanyDetail: async () => { 
        return await axiosApi().get("api/poker/get-company-detail").then(res=>res.data);
    },
    getBuyin: async (payload) => { //MaDA
        return await axiosApi().post("api/poker/get-buyin",payload).then(res=>res.data);
    },
    filterCustomer: async (payload) => { //MaDA
        return await axiosApi().post("api/poker/get-customer-filter",payload).then(res=>res.data);
    },
    getCardByMaKH: async (payload) => { //MaDA
        return await axiosApi().post("api/poker/get-card-bymakh",payload).then(res=>res.data);
    },
    getBanks: async () => { //MaDA
        return await axiosApi().post("api/poker/get-banks").then(res=>res.data);
    },
    getPaymentType: async () => { //MaDA
        return await axiosApi().post("api/poker/get-payment-type").then(res=>res.data);
    },
    getGiaiDau: async () => { //MaDA
        return await axiosApi().post("api/poker/get-giai-dau").then(res=>res.data);
    },
    getVoucher: async () => { //MaDA
        return await axiosApi().post("api/poker/get-voucher").then(res=>res.data);
    },
    getLoaiThe: async (payload) => { //MaDA
        return await axiosApi().post("api/poker/get-loai-the",payload).then(res=>res.data);
    },
    addBuyin: async (payload) => { //MaDA
        return await axiosApi().post("api/poker/add-buyin",payload).then(res=>res.data);
    },
    deleteBuyin: async (payload) => { //MaDA
        return await axiosApi().post("api/poker/delete-buyin",payload).then(res=>res.data);
    },
   
}
