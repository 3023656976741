import axiosApi from "./api_helper";
export const PermissionService = {
  getNhomQuyen: async () => {
    return await axiosApi()
      .post("api/poker/get-nhom-quyen")
      .then((res) => res.data);
  },
  addNhomQuyen: async (payload) => {
    return await axiosApi()
      .post("api/poker/add-update-permission", payload)
      .then((res) => res.data);
  },
  deleteNhomQuyen: async (payload) => {
    return await axiosApi()
      .post("api/poker/delete-permission", payload)
      .then((res) => res.data);
  },
  getForm: async (payload) => {
    return await axiosApi()
      .post("api/poker/get-form", payload)
      .then((res) => res.data);
  },
  updateAccessData: async (payload) => {
    return await axiosApi()
      .post("api/poker/update-accessdata", payload)
      .then((res) => res.data);
  },
  addAction: async (payload) => {
    return await axiosApi()
      .post("api/poker/add-action", payload)
      .then((res) => res.data);
  },
  deleteAction: async (payload) => {
    return await axiosApi()
      .post("api/poker/remove-action", payload)
      .then((res) => res.data);
  },
};
