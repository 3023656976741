import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect,useRef,useState } from "react";
import { HomeService } from "services/HomeService";
import {
  Button,
  Popconfirm,
  Table,
  Tooltip,
  notification,
  Dropdown,
  Space,
  Row,
  Col,
} from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { VscDebugStart } from "react-icons/vsc";

import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import { IoEyeSharp, IoSettingsOutline } from "react-icons/io5";
import { TourService } from "services/TourService";
import _ from 'lodash'
import './scss/index.scss'
import SettingBlind from "./SettingBlind";
import Toolbar from "components/Toolbar";
import { DownOutlined } from "@ant-design/icons";
import { OverlayPanel } from "primereact/overlaypanel";
export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
  });
  const refDetail = useRef(null);
  const refSettingBlind = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useEffect(()=>{
    filter(filterCondition);
  },[])
  const filter =async (_date) =>{
    setLoading(true);
    let _res = await HomeService.ManHinh.filter(_date)
    if(_res.daTa) setData(_res.daTa);
    setLoading(false);
  }
  const onChangeFilter = (_filter) =>{
    filter(_filter);
    setFilterCondition(_filter);
  }
  const onAfterSubmit = ()=>{
    filter(filterCondition);
  }
  const refMoreButton = useRef(null);
  const refMoreButtonItem = useRef(null);
  const columns = [
    {
      title: "Tên giải đấu",
      dataIndex: "tenGD",
      width: "500px",
    },
    {
      title: "Level hiện tại",
      dataIndex: "levelHienTai",
    },
    {
      title: "Total Entry",
      dataIndex: "totalEntry",
      render: (item) => (Format_Currency(item))
    },
    {
      title: "Ngày nhập",
      dataIndex: "ngayNhap",
      render: (item) => (Format_Date(item))
    },
    {
      title: "",
      fixed: "right",
      width:'200px',
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Cài đặt blind">
              <Button
                style={{ marginLeft: 5 }}
                type="primary"
                ghost
                icon={<IoSettingsOutline />}
                onClick={() => refSettingBlind.current.show(item.id)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 5 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Xem giải">
              <Button
                style={{ marginLeft: 5 }}
                type="primary"
                ghost
                icon={<IoEyeSharp />}
                onClick={() => openInNewTab(item)}
              />
            </Tooltip>
            <Button
              style={{ marginLeft: 5 }}
              type="primary"
              ghost
              onClick={(e) => {
                refMoreButton.current.toggle(e);
                refMoreButtonItem.current = item;
              }}
            >
              Khác
            </Button>
            <OverlayPanel ref={refMoreButton} style={{ width: "180px" }}>
              <Row gutter={[10, 5]}>
                <Col span={24}>
                  <Popconfirm
                    title="Xoá giải đấu"
                    description="Bạn có chắc chắn muốn xoá giải đấu?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => remove(refMoreButtonItem.current?.id)}
                  >
                    <Tooltip placement="topRight" title="Xoá">
                      <Button
                        style={{ width: "100%" }}
                        danger
                        ghost
                        icon={<CiTrash />}
                      >
                        Xoá giải
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </Col>
                <Col span={24}>
                  <Popconfirm
                    title="Bắt đầu giải đấu"
                    description="Bạn có chắc chắn muốn bắt đầu giải đấu?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => startTour(refMoreButtonItem.current?.id)}
                  >
                    <Tooltip placement="topRight" title="Bắt đầu">
                      <Button
                        style={{ width: "100%" }}
                        type="primary"
                        ghost
                        icon={<VscDebugStart />}
                      >
                        Bắt đầu giải
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </Col>
              </Row>
            </OverlayPanel>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await TourService.deleteGiaiDau({ ID: Number(value) });
    if(_res.status === 2000){
      let _data = _.cloneDeep(data);
      let fil = _data.filter(v=>v.id !==Number(value) );
      setData(fil);
      openNotification(
        "success",
        "topRight",
        "Xoá giải đấu thành công"
      );
    }else{
      openNotification("error", "topRight", _res?.message);
    }
  };
  const startTour = async (value) => {
    let _res = await TourService.startGiaiDau({ ID: Number(value) });
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Bạn đã bắt đầu giải đấu!");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const openInNewTab = (value) => {
    let url = "/#/portal?magd="+value.id+"&type="+value.loaiManHinh+"&mact="+localStorage.getItem("MaCTDK")
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  return (
    <Box pt={{ base: "120px", md: "60px", xl: "60px" }}>
      <Toolbar
        left={() => (
          <Button type="primary" onClick={() => refDetail.current.show()}>
            Thêm
          </Button>
        )}
        right={() => (
          <FilterModal
            filterCondition={filterCondition}
            onChangeFilter={onChangeFilter}
          />
        )}
      />
      <Table
        style={{ height: 100 }}
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 250,
          // x: 2500
        }}
        size="small"
      />
      {/* <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <DanhSachGiaiTable
          columnsData={columnsDataDevelopment}
          tableData={data}
          // onChangeDate={(p,v)=>onChangeDate(p,v)}
          // date={date}
        />
      </SimpleGrid> */}
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      <SettingBlind ref={refSettingBlind} />
      {contextHolder}
     
    </Box>
  );
}
