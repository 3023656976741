import { Box } from "@chakra-ui/react";
import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Table,
  QRCode,
  Button,
  Modal,
  Input,
  Popconfirm,
  Badge,
  Space,
  DatePicker,
  Popover,
} from "antd";
import { OrderService } from "services/OrdersService";
import { Format_Datetime } from "layouts/ultis/common";
import SignalrContext from "contexts/SignalrContext";
import _ from "lodash";
import ringer from "../../../assets/audio/donmoi.wav";
import notification from "../../../assets/audio/notification.wav";
import locale from "antd/locale/vi_VN";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import HoaDonToPrint from "components/HoaDonToPrint";
import { useReactToPrint } from "react-to-print";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function Index() {
  const [data, setData] = useState([]);
  const componentRef = useRef();
  const [modal, contextHolder] = Modal.useModal();

  const [dataAll, setDataAll] = useState([]);
  const [stateActive, setStateActive] = useState({
    key: "ALL",
    name: "Tất cả",
    color: "gray",
  });
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
  });
  const { hubConnection } = useContext(SignalrContext);
  const [state, setState] = useState([
    { key: "ALL", name: "Tất cả", color: "gray", total: 0 },
    { key: "NEW", name: "Mới", color: "purple", total: 0 },
    { key: "PENDING", name: "Đã tiếp nhận", color: "orange", total: 0 },
    { key: "RETURN", name: "Bếp trả", color: "blue", total: 0 },
    { key: "CANCEL", name: "Đã huỷ", color: "red", total: 0 },
    { key: "DONE", name: "Hoàn thành", color: "#52c41a", total: 0 },
  ]);
  useEffect(() => {
    filter(filterCondition);
  }, []);
  useEffect(() => {
    if (hubConnection) {
      hubConnection.on("NewOrder", async (response) => {
        console.log(response);
        let e = document.querySelector("#playAudio");
        e.play();
        e.loop = false;
        if (stateActive.key === "NEW" || stateActive.key === "ALL") {
          setData((pre) => {
            let _pre = _.cloneDeep(pre);
            _pre.unshift({
              ID: response.id,
              TenBan: response.tenBan,
              TenSeat: response.tenSeat,
              TotalPrice: response.totalPrice,
              DateCreate: response.dateCreate,
              State: "NEW",
            });
            return _pre;
          });
        }
        setDataAll((pre) => {
          let _pre = _.cloneDeep(pre);
          _pre.unshift({
            ID: response.id,
            TenBan: response.tenBan,
            TenSeat: response.tenSeat,
            TotalPrice: response.totalPrice,
            DateCreate: response.dateCreate,
            State: "NEW",
          });
          return _pre;
        });
        setState((pre) => {
          let _pre = _.cloneDeep(pre);
          _pre?.map((item) => {
            if (item.key === "ALL" || item.key === "NEW") item.total += 1;
          });
          return _pre;
        });
      });
      hubConnection.on("ChangeStateOrder", async (response) => {
        console.log(response);
        let e = document.querySelector("#playAudioNoti");
        e.play();
        e.loop = false;
        setData((pre) => {
          let _pre = _.cloneDeep(pre);
          let _item = _pre.find((v) => v.ID === response.id);
          if (_item) _item.State = response.state;
          return _pre;
        });
        setDataAll((pre) => {
          let _pre = _.cloneDeep(pre);
          let _item = _pre.find((v) => v.ID === response.id);
          if (_item) _item.State = response.state;
          return _pre;
        });
        setState((pre) => {
          let _pre = _.cloneDeep(pre);
          _pre?.map((item) => {
            if (response.state === "PENDING" && item.key === "NEW") {
              item.total -= 1;
            } else if (response.state === "PENDING" && item.key === "PENDING") {
              item.total += 1;
            } else if (response.state === "RETURN" && item.key === "PENDING") {
              item.total -= 1;
            } else if (response.state === "RETURN" && item.key === "RETURN") {
              item.total += 1;
            }
          });
          return _pre;
        });
      });
    }
  }, [hubConnection]);
  const filter = async (filterCondi) => {
    let _res = await OrderService.getOrders({
      TuNgay: filterCondi.TuNgay,
      DenNgay: filterCondi.DenNgay,
    });
    if (_res.data) {
      setDataAll(_res.data);
      setData(_res.data);
      let _states = _.cloneDeep(state);
      _states.map((item) => {
        if (item.key === "ALL") {
          item.total = _res.data?.length;
        } else {
          item.total = _res?.data?.filter((v) => v.State === item.key)?.length;
        }
      });
      setState(_states);
    }
  };
  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const confirm = (item) => {
    modal.confirm({
      title: "Tiếp nhận",
      icon: <ExclamationCircleOutlined />,
      content: "Bạn có chắc chắn tiếp nhận đơn hàng?",
      okText: "Đồng ý",
      cancelText: "Không",
      onOk: ()=>onTiepNhan(item, "PENDING"),
    });
  };
   const confirm2 = (item) => {
     modal.confirm({
       title: "Bếp trả",
       icon: <ExclamationCircleOutlined />,
       content: "Bạn có chắc chắn trả đơn hàng?",
       okText: "Đồng ý",
       cancelText: "Không",
       onOk: () => onTiepNhan(item, "RETURN"),
     });
   };
  const columns = [
    {
      title: "Đơn",
      // dataIndex: 'Name',
      key: "ID",
      render: (item) => {
        return (
          <div>
            <p>
              Đơn hàng: {item.TenBan} - {item.TenSeat}
            </p>
          </div>
        );
      },
    },
    {
      title: "Ngày",
      // dataIndex: 'Name',
      render: (item) => {
        return (
          <div>
            <p>{Format_Datetime(item.DateCreate)}</p>
          </div>
        );
      },
    },
    {
      title: "Thanh toán",
      render: (item) => {
        return (
          <div>
            <Badge
              className="site-badge-count-109"
              count={item.IsPay ? "Đã thanh toán" : "Chưa thanh toán"}
              // style={{ backgroundColor: '#52c41a' }}
              // style={{ backgroundColor: 'orange' }}
              style={{ backgroundColor: item.IsPay ? "#52c41a" : "red" }}
            />
          </div>
        );
      },
    },
    {
      title: "Trạng thái",
      // dataIndex: 'Name',
      render: (item) => {
        return (
          <div>
            <Badge
              className="site-badge-count-109"
              count={state.find((v) => v.key === item.State)?.name}
              style={{
                backgroundColor:
                  item.State === "NEW"
                    ? "purple"
                    : item.State === "PENDING"
                    ? "orange"
                    : item.State === "RETURN"
                    ? "blue"
                    : item.State === "CANCEL"
                    ? "red"
                    : "#52c41a",
              }}
            />
          </div>
        );
      },
    },
    {
      title: "",
      render: (item) => {
        return (
          <div>
            <Button onClick={() => print(item)} style={{ marginRight: 10 }}>
              In hoá đơn
            </Button>
            {item.State === "NEW" && (
              // <Popconfirm
              //   title="Tiếp nhận"
              //   description="Bạn có chắc chắn tiếp nhận đơn hàng?"
              //   okText="Đồng ý"
              //   cancelText="Không"
              //   onConfirm={() => onTiepNhan(item, "PENDING")}
              // >
              // <Popover
              //   content={<div></div>}
              //   title="Tiếp nhận"
              //   trigger="click"
              //   open={open}
              //   onOpenChange={handleOpenChange}
              // >
              <Button
                style={{ marginRight: 10 }}
                danger
                ghost
                onClick={() => confirm(item)}
              >
                Tiếp nhận
              </Button>
            )}
            {item.State === "PENDING" && (
              // <Popconfirm
              //   title="Bếp trả"
              //   description="Bạn có chắc chắn trả đơn hàng?"
              //   okText="Đồng ý"
              //   cancelText="Không"
              //   onConfirm={() => onTiepNhan(item, "RETURN")}
              // >
              <Button
                type="primary"
                style={{
                  marginRight: 10,
                  borderColor: "blue",
                  color: "blue",
                }}
                ghost
                primaryColor="red"
                textHoverBg="red"
                onClick={() => confirm2(item)}
              >
                Bếp trả
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  const [products, setProducts] = useState([]);
  const print = async (value) => {
    let _res = await OrderService.getOrderDetail({
      MaOrder: value.ID,
    });
    if (_res.status === 2000) {
      setProducts(_res.data);
      handlePrint();
    }
  };
  const onTiepNhan = async (item, state) => {
    let _res = await OrderService.changeStateOrders({
      ID: item.ID,
      State: state,
    });
    if (_res.status === 2000) {
      let _datas = _.cloneDeep(data);
      let _data = _datas.find((v) => v.ID === item.ID);
      _data.State = state;
      setData(_datas);
    }
  };
  const expandedRowRender = (row) => {
    const columns = [
      {
        title: "Món",
        dataIndex: "TenSP",
      },
      {
        title: "Số lượng",
        dataIndex: "Quantity",
      },
      {
        title: "Ghi chú",
        dataIndex: "Note",
      },
    ];
    return (
      <Table
        columns={columns}
        dataSource={dataDetail[row.ID]}
        pagination={false}
      />
    );
  };
  const [dataDetail, setDataDetail] = useState({});
  const loadDataExpand = async (value) => {
    let _res = await OrderService.getOrderDetail({
      MaOrder: value.ID,
    });
    let _dt = _.cloneDeep(dataDetail);
    _dt[value.ID] = _res.data;
    setDataDetail(_dt);
  };
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "TuNgay":
        _filter.TuNgay = v;
        break;
      case "DenNgay":
        _filter.DenNgay = v;
        break;
    }
    setFilterCondition(_filter);
    filter(_filter);
  };
  const changeState = (state) => {
    if (state === "ALL") {
      setData(dataAll);
    } else {
      let _data = dataAll.filter((v) => v.State === state);
      setData(_data);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Box pt={{ base: "130px", md: "60px", xl: "60px" }}>
      <Space size="middle" style={{ marginBottom: 10 }}>
        <div style={{ display: "none" }}>
          <HoaDonToPrint ref={componentRef} products={products} />
        </div>
        <DatePicker
          format="DD-MM-YYYY"
          defaultValue={dayjs(filterCondition.TuNgay)}
          placeholder="Từ ngày"
          locale={locale}
          onChange={(v) => applyChangeFilter("TuNgay", v)}
        />
        <DatePicker
          format="DD-MM-YYYY"
          defaultValue={dayjs(filterCondition.DenNgay)}
          placeholder="Đến ngày"
          onChange={(v) => applyChangeFilter("DenNgay", v)}
        />
        {state?.map((item) => {
          return (
            <Badge
              key={item.key}
              count={item.total}
              onClick={() => {
                setStateActive(item);
                changeState(item.key);
              }}
            >
              <div
                style={{
                  border: `1px solid ${item.color}`,
                  padding: "5px 15px",
                  cursor: "pointer",
                  borderRadius: 10,
                  background: stateActive.key === item.key ? item.color : null,
                  color: stateActive.key === item.key ? "white" : null,
                }}
              >
                <p>{item.name}</p>
              </div>
            </Badge>
          );
        })}
      </Space>
      <Table
        dataSource={data}
        columns={columns}
        rowKey="ID"
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ["0"],
        }}
        expandIcon={({ expanded, onExpand, record }) =>
          expanded ? (
            <Button
              onClick={(e) => {
                onExpand(record, e);
              }}
            >
              Rút gọn
            </Button>
          ) : (
            <Button
              onClick={(e) => {
                onExpand(record, e);
                loadDataExpand(record);
              }}
            >
              Chi tiết
            </Button>
          )
        }
      />
      <audio loop id="playAudio">
        <source src={ringer} />
      </audio>
      <audio loop id="playAudioNoti">
        <source src={notification} />
      </audio>
      {contextHolder}
    </Box>
  );
}
