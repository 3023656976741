import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
} from "antd";
import { BuyinService } from "services/BuyinService";
import _ from "lodash";
import DebounceSelect from "components/DebounceSelect";
import BuyinToPrint from "components/BuyinToPrint";
import { useReactToPrint } from "react-to-print";

const { TextArea } = Input;
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [company, setCompany] = useState({});
  const [banks, setBanks] = useState([]);
  const [giaiDau, setGiaiDau] = useState([]);
  const [voucher, setVoucher] = useState([]);
  const [paymentType, setPaymentType] = useState([]);
  const refPrint = useRef(null);
  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  const loadCategories = async () => {
    let _banks = await BuyinService.getBanks();
    let _pm = await BuyinService.getPaymentType();
    let _gd = await BuyinService.getGiaiDau();
    let _vc = await BuyinService.getVoucher();
    let _cp = await BuyinService.getCompanyDetail();
    setBanks(_banks?.data ?? []);
    setPaymentType(_pm?.data ?? []);
    setGiaiDau(_gd?.data ?? []);
    setVoucher(_vc?.data ?? []);
    setCompany(_cp?.data[0] ?? {});
    console.log(_cp?.data[0]);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      loadCategories();
    },
    update: async (data) => {
      setOpen(true);
      loadCategories();
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    const SumTongTien = (_data) => {
      _data.TongTien =
        _data.Tien -
        (_data.TienCk ?? 0) -
        (_data.TienVoucher ?? 0) -
        (_data.TienTru ?? 0);
    };
    switch (p) {
      case "MaKH":
        console.log(v);
        if (v?.length > 1) {
          _data[p] = v.splice(1);
          _data.TenCongTy = _data?.MaKH[0].label.split("-")[0];
          _data.DiDong = _data?.MaKH[0].label.split("-")[1];
        } else if (v?.length === 0) {
          _data[p] = null;
        } else {
          _data[p] = v;
          _data.TenCongTy = _data?.MaKH[0].label.split("-")[0];
          _data.DiDong = _data?.MaKH[0].label.split("-")[1];
        }
        if (v?.length > 0) {
          let _res = await BuyinService.getCardByMaKH({
            MaKH: _data[p][0].value,
          });
          _data.MaVach = _res?.data?.maVach;
          _data.MaThe = _res?.data?.id;
          _data.SoThe = _res?.data?.soThe;
          _data.Diem = _res?.data?.diemThe;
          _data.MaLT = _res?.data?.maLT;
          _data.TyLeCK = 0;
          _data.DiemTru = 0;
        }
        break;
      case "MaGD":
        let _gd = giaiDau?.find((d) => d.ID === v);
        console.log(_gd);
        _data[p] = v;
        _data.TenGD = _gd?.TenGD;
        _data.TienGiai = _gd?.TienGiaiBuyIn;
        _data.TienPhi = _gd?.GiaBuyin;
        _data.TienBounty = _gd?.TienBountyBuyIn;
        _data.TienJackport = _gd?.TienGiaiBuyIn / 100;
        _data.Tien = _gd?.TienGiaiBuyIn + _gd?.GiaBuyin + _gd?.TienBountyBuyIn;
        SumTongTien(_data);
        break;
      case "TyLeCK":
        _data[p] = v;
        _data.TienCk = ((_data?.TienGiai + _data?.TienPhi) * v) / 100;
        SumTongTien(_data);
        break;
      case "MaVoucher":
        _data[p] = v;
        let _vc = voucher?.find((d) => d.ID === v);
        _data.TienVoucher = _vc.SoTien;
        SumTongTien(_data);
        break;
      case "DiemTru":
        _data[p] = v;
        let _reslt = await BuyinService.getLoaiThe({ MaLT: _data.MaLT });
        _data.TienTru = v * (_reslt?.data?.MotDiemTrenSoTien ?? 0);
        SumTongTien(_data);
        break;
      case "MaHinhThuc":
        let ht = paymentType.find((d) => d.ID === v);
        _data[p] = v;
        _data.TenHT = ht?.TenHinhThuc;
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  async function fetchUserList(username) {
    return BuyinService.filterCustomer({ InputSearch: username }).then(
      (res) => {
        return res?.data?.map((item) => ({
          label: `${item.TenCongTy} - ${item.DiDong} - ${new Date(
            item.NgaySinh
          ).getDate()}/${new Date(item.NgaySinh).getMonth() + 1}/${new Date(
            item.NgaySinh
          ).getFullYear()}`,
          value: item.MaKH,
          TenKH: item.TenCongTy,
          DiDong: item.DiDong,
        }));
      }
    );
  }
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const [soTT, setSoTT] = useState(0);
  const onSubmit = async (print) => {
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      return;
    }
    _data.MaKHMT = _data?.MaKH[0].value;
    let _res = await BuyinService.addBuyin(_data);
    if (_res.status === 2000) {
      setSoTT(_res.data?.soTT);
      openNotification("success", "topRight", "BUYIN thành công!");
      if (print) handlePrint();
      setData({});
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => refPrint.current,
  });
  return (
    <Drawer
      title="BUYIN"
      width={1220}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button onClick={() => onSubmit()}>Lưu</Button>
          <Button onClick={() => onSubmit("print")} type="primary">
            Lưu & in
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[24, 8]}>
        <Col span={12} className="gutter-row">
          <p>
            Khách hàng <span style={{ color: "red" }}>*</span>
          </p>
          <DebounceSelect
            mode="multiple"
            value={data?.MaKH}
            status={data?.MaKH ? "" : "error"}
            placeholder="Tìm kiểm khách hàng"
            fetchOptions={fetchUserList}
            onChange={(newValue) => applyChange("MaKH", newValue)}
            style={{
              width: "100%",
            }}
          />
          <p style={{ color: "red" }}>{errors?.MaKH}</p>
        </Col>
        <Col span={12} className="gutter-row">
          <p>
            Giải đấu <span style={{ color: "red" }}>*</span>{" "}
          </p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaGD}
            status={data?.MaGD ? "" : "error"}
            showSearch
            filterOption={(input, option) =>
              (option?.TenGD?.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            onChange={(v) => applyChange("MaGD", v)}
            options={giaiDau}
            fieldNames={{ label: "TenGD", value: "ID" }}
          />
          <p style={{ color: "red" }}>{errors?.MaGD}</p>
        </Col>
        <Col span={6} className="gutter-row">
          <p>Mã vạch</p>
          <Input value={data?.MaVach} disabled placeholder="Mã vạch" />
        </Col>
        <Col span={6} className="gutter-row">
          <p>Số thẻ</p>
          <Input value={data?.SoThe} placeholder="Số thẻ" disabled />
        </Col>

        <Col span={data?.MaHinhThuc === 2 ? 4 : 6} className="gutter-row">
          <p>
            Hình thức thanh toán <span style={{ color: "red" }}>*</span>
          </p>
          <Select
            style={{
              width: "100%",
            }}
            status={data?.MaHinhThuc ? "" : "error"}
            value={data?.MaHinhThuc}
            placeholder="Chọn hình thức"
            onChange={(v) => applyChange("MaHinhThuc", v)}
            options={paymentType}
            fieldNames={{ label: "TenHinhThuc", value: "ID" }}
          />
          <p style={{ color: "red" }}>{errors?.MaHinhThuc}</p>
        </Col>
        {data?.MaHinhThuc === 2 && (
          <Col span={4} className="gutter-row">
            <p>Ngân hàng</p>
            <Select
              // defaultValue={banks?.length > 0 && banks[0].ID}
              style={{
                width: "100%",
              }}
              value={data?.MaNH}
              placeholder="Chọn ngân hàng"
              showSearch
              filterOption={(input, option) =>
                (option?.TenNHVT?.toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              onChange={(v) => applyChange("MaNH", v)}
              options={banks}
              fieldNames={{ label: "TenNHVT", value: "ID" }}
            />
          </Col>
        )}
        <Col span={data?.MaHinhThuc === 2 ? 4 : 6} className="gutter-row">
          <p>Voucher</p>
          <Select
            // defaultValue={banks?.length > 0 && banks[0].ID}
            style={{
              width: "100%",
            }}
            value={data?.MaVoucher}
            placeholder="Chọn voucher"
            onChange={(v) => applyChange("MaVoucher", v)}
            options={voucher}
            fieldNames={{ label: "LyDo", value: "ID" }}
          />
        </Col>
      </Row>

      <Row gutter={[24, 8]} style={{ marginTop: 15 }}>
        <Col span={6}>
          <p>Tiền giải</p>
          <InputNumber
            style={{ width: "100%" }}
            disabled
            addonAfter="vnđ"
            value={data?.TienGiai}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
          />
        </Col>
        <Col span={6}>
          <p>Tiền phí</p>
          <InputNumber
            style={{ width: "100%" }}
            value={data?.TienPhi}
            disabled
            addonAfter="vnđ"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
          />
        </Col>
        <Col span={6}>
          <p>Tiền bounty</p>
          <InputNumber
            style={{ width: "100%" }}
            disabled
            value={data?.TienBounty}
            addonAfter="vnđ"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
          />
        </Col>
        <Col span={6}>
          <p>Tổng tiền</p>
          <InputNumber
            style={{ width: "100%" }}
            disabled
            value={data?.Tien}
            addonAfter="vnđ"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
          />
        </Col>

        <Col span={6}>
          <p>Điểm trừ</p>
          <InputNumber
            addonAfter="điểm"
            style={{ width: "100%" }}
            max={data?.DiemTru}
            onChange={(newValue) => applyChange("DiemTru", newValue)}
          />
        </Col>
        <Col span={6}>
          <p>Điểm đã tích</p>
          <InputNumber
            style={{ width: "100%" }}
            value={data?.Diem}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
            disabled
            addonAfter="điểm"
          />
        </Col>

        <Col span={6}>
          <p>Tiền trừ</p>
          <InputNumber
            style={{ width: "100%" }}
            value={data?.TienTru}
            disabled
            addonAfter="vnđ"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
          />
        </Col>
        <Col span={6}>
          <p>Tiền jackport</p>
          <InputNumber
            style={{ width: "100%" }}
            value={data?.TienJackport}
            disabled
            addonAfter="vnđ"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>

        <Col span={6}>
          <p>Tỷ lệ CK</p>
          <InputNumber
            style={{ width: "100%" }}
            addonAfter="%"
            value={data?.TyLeCK}
            onChange={(newValue) => applyChange("TyLeCK", newValue)}
          />
        </Col>
        <Col span={6}>
          <p>Tiền chiết khấu</p>
          <InputNumber
            style={{ width: "100%" }}
            value={data?.TienCk}
            disabled
            addonAfter="vnđ"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
          />
        </Col>
        <Col span={6}>
          <p>Tiền voucher</p>
          <InputNumber
            style={{ width: "100%" }}
            value={data?.TienVoucher}
            disabled
            addonAfter="vnđ"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>

        <Col span={6}>
          <p>Còn lại</p>
          <InputNumber
            style={{ width: "100%" }}
            disabled
            value={data?.TongTien}
            addonAfter="vnđ"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>
      </Row>
      <TextArea
        onChange={(e) => applyChange("GhiChu", e.target.value)}
        style={{ marginTop: 15 }}
        placeholder="Ghi chú"
      />
      <div style={{ display: "none" }}>
        <BuyinToPrint
          ref={refPrint}
          data={data}
          company={company}
          soTT={soTT}
        />
      </div>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
