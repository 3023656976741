import { Format_Currency } from "layouts/ultis/common";
import * as React from "react";

 const HoaDonToPrint = (props, ref) => {
   const {products} = props;
    return (
      <div  ref={ref} style={{margin:"0px 5px 5px"}}>
        <div style={{textAlign:'center',padding:"15px 0px"}}>
          <p style={{fontSize:15,fontWeight:'bold'}}>Công ty TNHH Beesky</p>
          <p>www.mtv.org.vn</p>
          <p>Số 7 Lê Văn Lương - Thanh Xuân - Hà Nội</p>
          <p>09876521</p>
        </div>
        <hr />
        <div style={{padding:'10px 0px'}}>
        <p style={{fontSize:'20px',fontWeight:'bold',textAlign:'center'}}>HOÁ ĐƠN</p>
        <p>Số CT: admin_123_xyc</p>
        <p>Ngày CT: 04/12/2023 08:30</p>
        <p>Khách hàng: Khách lẻ</p>
        <p>Điện thoại: 0987654321</p>
        <p>Nhân viên: admin</p>
        </div>
        <hr />

        <table style={{marginTop:10,width:'100%'}}>
          <tr style={{textAlign:'left'}}>
            <th>SL</th>
            <th>Đ.Giá</th>
            <th>CK</th>
            <th>T.Tiền</th>
          </tr>
          {products?.map((item)=>{
            return  <tr>
            <td style={{width:'40%'}}>{item.TenSP} x{item.Quantity}</td>
            <td style={{width:'20%'}}>{Format_Currency(item.Price)}</td>
            <td style={{width:'20%'}}>0</td>
            <td style={{width:'20%'}}>{Format_Currency(item.Price*item.Quantity)}</td>
          </tr>
          })}
          <tfoot styl={{margin:"10px 0px"}}>
            <td style={{fontWeight:'bold'}}>Tổng cộng:</td>
            <td></td>
            <td></td>
            <td style={{fontWeight:'bold'}}>{Format_Currency(
              products?.reduce((pre,current)=>{
                return pre += (current.Price*current.Quantity)
              },0)
            )}</td>
          </tfoot>
         
        </table>
      </div>
    );
  };
export default React.forwardRef(HoaDonToPrint);