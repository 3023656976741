import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Select, Drawer, Divider, Checkbox, Row, Col } from "antd";
import _ from "lodash";
import { PermissionService } from "services/PermissionService";

const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [perID, setPerID] = useState(0);
  const loadCategories = async (_perid) => {
    let _res = await PermissionService.getForm({ PerID: _perid });
    setData(_res?.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async (_perid) => {
      setOpen(true);
      setPerID(_perid);
      loadCategories(_perid);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
    onAfterSubmit();
  };
  const onChangePer = async (item) => {
    if (item.per) {
      let payload = {
        PerID: perID,
        FormID: item.formID,
        HavePer: null,
      };
      let _res = await PermissionService.updateAccessData(payload);
      if (_res.status === 2000) {
        let _data = _.cloneDeep(data);
        let _dt = _data.find((v) => v.formID === item.formID);
        _dt.per = null;
        setData(_data);
      }
    } else {
      let payload = {
        PerID: perID,
        FormID: item.formID,
        HavePer: true,
      };
      let _res = await PermissionService.updateAccessData(payload);
      if (_res.status === 2000) {
        let _data = _.cloneDeep(data);
        let _dt = _data.find((v) => v.formID === item.formID);
        _dt.per = item.formID;
        setData(_data);
      }
    }
  };
  const handleChange = async (v, item) => {
    let _data = _.cloneDeep(data);
    let _dt = _data.find((v) => v.formID === item.formID);
    let hascheck = _.map(
      _dt.actions?.filter((v) => v.isCheck),
      "id"
    );
    let _res = null;
    if (hascheck?.length > v.length) {
      //remove
      let id = _.difference(hascheck, v);
      _res = await PermissionService.deleteAction({
        PerID: perID,
        FormID: item.formID,
        FeatureID: id[0],
      });
    } else {
      let id = _.difference(v, hascheck);
      console.log(id);
      _res = await PermissionService.addAction({
        PerID: perID,
        FormID: item.formID,
        FeatureID: id[0],
      });
    }
    if (_res?.status === 2000) {
     _dt.actions?.map((item) => {
       item.isCheck = v.includes(item.id);
     });
     setData(_data);
    }
     
  };

  return (
    <Drawer
      title="PHÂN QUYỀN"
      width={720}
      onClose={onClose}
      open={open}
      className="giai-dau-detail"
      styles={{
        body: {
          paddingTop: 30,
        },
      }}
    >
      {data?.map((item) => {
        return (
          <div key={item.formID}>
            <Row>
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 8,
                  }}
                >
                  <Checkbox
                    onChange={() => onChangePer(item)}
                    checked={item.per}
                  />
                  <p style={{ fontWeight: "500", fontSize: "16px" }}>
                    {item.formName}
                  </p>
                </div>
              </Col>
              <Col span={12}>
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Chọn action"
                  onChange={(v) => handleChange(v, item)}
                  fieldNames={{ label: "name", value: "id" }}
                  value={_.map(
                    item.actions.filter((v) => v.isCheck),
                    "id"
                  )}
                  maxTagCount="responsive"
                  options={item.actions}
                />
              </Col>
            </Row>

            <Divider dashed style={{ margin: "8px 0px" }} />
          </div>
        );
      })}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
