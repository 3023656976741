import React from "react";

import { Icon } from "@chakra-ui/react";
import { MdBarChart, MdHome, MdLock, MdOutlineChecklist, MdPlaylistAddCheck } from "react-icons/md";
import { IoFastFood } from "react-icons/io5";
import { CiViewTable } from "react-icons/ci";
import { BsPersonBoundingBox } from "react-icons/bs";
import { FaIdCard } from "react-icons/fa";
import { LuGitBranchPlus } from "react-icons/lu";
import { MdTour } from "react-icons/md";
import { BsPersonFillLock } from "react-icons/bs";
import { CiBoxList } from "react-icons/ci";
import { BiListMinus } from "react-icons/bi";
import { CgPlayListAdd } from "react-icons/cg";
import { GoDotFill } from "react-icons/go";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import DanhSachGiai from "views/admin/DanhSachGiai/index";
import DanhSachBan from "views/admin/DanhSachBan/index";
import DanhSachOrder from "views/admin/DanhSachOrders/index";
import DanhSachBuyin from "views/admin/LichSuNapTien/index";
import DanhSachThe from "views/admin/DanhSachThe/index";
import LichSuRaVao from "views/admin/HeThong/LichSuRaVao/index";

import DanhSachNhanVien from "views/admin/HeThong/NhanVien/index";
import PhanQuyen from "views/admin/HeThong/PhanQuyen/index";
import DanhSachPhieuThu from "views/admin/PhieuThu/index";
import DanhSachPhieuChi from "views/admin/PhieuChi/index";
import DanhSachCongTy from "views/admin/HeThong/CongTy/index";
import DanhSachSanPham from "views/admin/HeThong/SanPham/index";
import DanhSachManHinh from "views/admin/HeThong/DanhSachManHinh/index";

// Auth Imports
import SignInCentered from "views/auth/signIn";

const routes = [
  {
    key: "danh-sach-giai",
    label: "Danh sách giải",
    layout: "/admin",
    icon: <Icon as={MdTour} width="20px" height="20px" color="inherit" />,
    path: "/danh-sach-giai",
    component: DanhSachGiai,
    id: 193,
  },
  {
    key: "danh-sach-buyin",
    label: "Danh sách buyin",
    layout: "/admin",
    icon: (
      <Icon as={LuGitBranchPlus} width="20px" height="20px" color="inherit" />
    ),
    path: "/danh-sach-buyin",
    component: DanhSachBuyin,
    id: 162,
  },
  {
    key: "danh-sach-the",
    label: "Danh sách thẻ",
    layout: "/admin",
    icon: <Icon as={FaIdCard} width="20px" height="20px" color="inherit" />,
    path: "/danh-sach-the",
    component: DanhSachThe,
    id: 142,
  },

  {
    key: "lich-su-ra-vao",
    label: "Lịch sử ra vào",
    layout: "/admin",
    icon: (
      <Icon
        as={MdOutlineChecklist}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/lich-su-ra-vao",
    component: LichSuRaVao,
    id: 114,
  },
  {
    key: "danh-sach-order",
    label: "Danh sách order",
    layout: "/admin",
    icon: <Icon as={IoFastFood} width="20px" height="20px" color="inherit" />,
    path: "/danh-sach-order",
    component: DanhSachOrder,
    id: 114,
  },
  {
    label: "Jackpot",
    layout: "/admin",
    icon: (
      <Icon
        as={MdPlaylistAddCheck}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    children: [
      {
        key: "danh-sach-phieu-thu",
        label: "Phiếu thu",
        layout: "/admin",
        icon: (
          <Icon as={CgPlayListAdd} width="20px" height="20px" color="inherit" />
        ),
        path: "/danh-sach-phieu-thu",
        component: DanhSachPhieuThu,
        id: 199,
      },
      {
        key: "danh-sach-phieu-chi",
        label: "Phiếu chi",
        layout: "/admin",
        icon: (
          <Icon as={BiListMinus} width="20px" height="20px" color="inherit" />
        ),
        path: "/danh-sach-phieu-chi",
        component: DanhSachPhieuChi,
        id: 200,
      },
    ],
  },
  {
    label: "Hệ thống",
    layout: "/admin",
    icon: (
      <Icon as={BsPersonFillLock} width="20px" height="20px" color="inherit" />
    ),
    children: [
      {
        key: "danh-sach-nhan-vien",
        label: "Nhân viên",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/danh-sach-nhan-vien",
        component: DanhSachNhanVien,
        id: 15,
      },
      {
        key: "danh-sach-cong-ty",
        label: "Công ty",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/danh-sach-cong-ty",
        component: DanhSachCongTy,
        id: 148,
      },
      {
        key: "danh-sach-san-pham",
        label: "Sản phẩm",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/danh-sach-san-pham",
        component: DanhSachSanPham,
        id: 87,
      },
      {
        key: "phan-quyen",
        label: "Phân quyền",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/phan-quyen",
        component: PhanQuyen,
        id: 62,
      },
      {
        key: "danh-sach-ban",
        label: "Danh sách bàn",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/danh-sach-ban",
        component: DanhSachBan,
        id: 20,
      },
      {
        key: "danh-sach-man-hinh",
        label: "Danh sách màn hình",
        layout: "/admin",
        icon: (
          <Icon as={GoDotFill} width="20px" height="20px" color="inherit" />
        ),
        path: "/danh-sach-man-hinh",
        component: DanhSachManHinh,
        id: 206,
      },
    ],
  },

  {
    label: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
];

export default routes;
