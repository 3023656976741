import React, { useEffect, useState, useMemo } from "react";
import "./scss/index.scss";
import { Format_Currency } from "layouts/ultis/common";
import _ from "lodash";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

export default function History({ ...props }) {
  const {  onBack } = props;
  const [data, setData] = useState([]);
  const panelStyle = {
    marginBottom: 14,
    background: "#B0D1AE",
    borderRadius: 10,
    border: "none",
  };
  useEffect(() => {
    let _ors = localStorage.getItem("orders");
    if (_ors) setData(JSON.parse(_ors));
  }, []);
  const getItems = () => {
    let items = [];
    _.orderBy(data,"Date","desc")?.map((item, i) => {
      items.push({
        key: i,
        label: (
          <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
          <p>Đặt món: {item.TenBan}, {item.TenSeat}</p>
          <p>{new Date(item.Date).getDate()}/{new Date(item.Date).getFullYear()}</p>
          </div>
        ),
        children: item.Products?.map((pr) => {
          return (
            <div style={{ borderBottom: "1px solid", padding: "10px 0px" }}>
              <p>
                {pr.TenSP} x
                <span style={{ fontWeight: "bold" }}>{pr.Quantity}</span>
              </p>
              <p>{pr.Note}</p>
              <p style={{ fontWeight: "bold" }}>{Format_Currency(pr.Price)}</p>
            </div>
          );
        }),
        style: panelStyle,
      });
    });
    return items;
  };
  const renderItem = () => {
    return (
      <Collapse
        bordered={false}
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        // style={{ background: 'rgb(251 251 251)' }}
        items={getItems()}
      />
    );
  };

  return (
    <div>
      <div
        style={{ padding: "0px 10px", background: "white", height: "100vh" }}
      >
        <div
          style={{
            position: "sticky",
            background: "white",
            zIndex: 99,
            top: 0,
            borderBottom: "1px solid gray",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ChevronLeftIcon boxSize={6} />
          <p onClick={() => onBack()} style={{ padding: "10px 0px" }}>
            Trở lại
          </p>
        </div>
        <p style={{ margin: "15px 0px" }}>* Các đơn đã đặt</p>
        {renderItem()}
      </div>
    </div>
  );
}
