import React from "react";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align="center" direction="column">
      {/* <HorizonLogo h='26px' w='175px' my='32px' color={logoColor} /> */}
      {/* <p style={{
        // height:"26px", 
      margin:"15px 0",
    fontSize:"20px",
    fontWeight:'900'
    }}>BEE POKER</p> */}
      <p
        style={{
          margin: "15px 0px",
          fontSize: "20px",
          fontWeight: "bold",
          height: "26px",
        }}
      >
        {localStorage.getItem("MaCTDK")?.toLocaleUpperCase()}
      </p>
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
