import axios from "axios";
import { post } from "./api_helper";
import axiosApi from "./api_helper";
export const OtherService = {
  getDevice: async (payload) => {
    return await axiosApi()
      .post("api/poker/get-device", payload)
      .then((res) => res.data);
  },
  addDevice: async (payload) => {
    return await axiosApi()
      .post("api/poker/add-new-device", payload)
      .then((res) => res.data);
  },
  updateDevice: async (payload) => {
    return await axiosApi()
      .post("api/poker/update-device", payload)
      .then((res) => res.data);
  },
  deleteDevice: async (payload) => {
    return await axiosApi()
      .post("api/poker/delete-device", payload)
      .then((res) => res.data);
  },
};
