import React, { useEffect, useState, useMemo } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import "./scss/index.scss";
import { Tabs } from "antd";
import { OrderService } from "services/OrdersService";
import Sticky from "react-stickynode";
import { Format_Currency } from "layouts/ultis/common";
import _ from 'lodash';
import {  ChevronLeftIcon,AddIcon,MinusIcon, } from '@chakra-ui/icons'
import { Input,Select } from 'antd';

const { Option } = Select;
export default function CheckOut({...props}) {
  const {carts ,onBack  , onChangeNote ,removeProduct ,addProduct } = props;
  const [table,setTable] = useState({})
  let params = useLocation();
  const [seat,setSeat] = useState(null);
  const [error,setError] = useState("")
 useEffect(() => {
   let indextable = params?.search?.indexOf("&table");
   let _table = params?.search.substring(indextable + 7);
   (async () => {
     let _resTable = await OrderService.getTableDetail({ ID: Number(_table) });
     setTable(_resTable?.data);
   })();
 }, []);
  const renderItem = () => {
    return carts?.map((item) => {
      return (
        <div
          className="box-product"
          key={item.id}
          style={{
            boxShadow: "1px 3px 12px 1px rgba(208, 216, 243, 0.6)",
            marginBottom: 18,
            padding: 20,
            marginTop:10
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 15,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", gap: 15, alignItems: "center",width:'90%' }}>
              <img
                src="https://openclipart.org/image/2000px/289282"
                style={{ width: 40, height: 40 }}
              />
              <div style={{width:'90%'}}>
                <p>{item.tenSP}</p>
                
                <Input placeholder="Thêm ghi chú" style={{border:'none',padding:'8px 0px'}}
                onChange={(v)=>onChangeNote(v.target.value,item)}
                defaultValue={item.note}
                />
                <p style={{ fontWeight: "700" }}>
                  {Format_Currency(item.giaBan)}
                </p>
              </div>
            </div>
            <div style={{display:'flex',gap:10,alignItems:'center'}}>
                <MinusIcon boxSize={4} style={{border:'1px solid',padding:2}} onClick={()=>removeProduct(item)} />
                <p>{carts?.find(v=>v.id===item.id)?.quantity }</p>
                <AddIcon boxSize={4}  style={{border:'1px solid',padding:2}} onClick={()=>addProduct(item)} />
             </div>
            {/* <p style={{fontWeight:'bold'}}>{carts?.find(v=>v.id===item.id)?.quantity }</p> */}
            
          </div>
        </div>
      );
    });
  };
  const onGenderChange = (value) => {
    setSeat(value);
    setError("");
  };
 
  const submit = async () =>{
    if(!seat){
      setError("Vui lòng chọn Seat.")
      return;
    }
    let _products = [];
    carts?.map((item)=>{
      _products.push({
        Quantity: item.quantity,
        MaSP: item.id,
        Price: item.giaBan,
        Note: item.note,
        TenSP: item.tenSP
      })
    })
    let _payload = {
      TenCTDK: localStorage.getItem("tenCTDK"),
      TenBan: table.Name,
      TenSeat: "Seat " + seat,
      MaBan: Number(table.ID),
      MaSeat: Number(seat),
      Date: new Date(),
      TotalPrice: carts?.reduce((pre, current) => {
        return (pre += current?.quantity * current.giaBan);
      }, 0),
      Products: _products,
    };
    let _res = await OrderService.checkout(_payload);
    if(localStorage.getItem("orders")){
      let _orders = JSON.parse(localStorage.getItem("orders"));
      _orders.push(_payload)
      localStorage.setItem("orders",JSON.stringify(_orders));
    }else{
      let _orders = [];
      _orders.push(_payload)
      localStorage.setItem("orders",JSON.stringify(_orders));
    }
    onBack(_res);
  }
  return (
    <div>


      <div style={{ padding: "0px 10px", background: "white", height: "100vh" }}>
        <div style={{ position: "sticky", background: "white", zIndex: 99, top: 0 , borderBottom:'1px solid gray',
      display:'flex',alignItems:'center'
      }}>
          <ChevronLeftIcon boxSize={6}  />
          <p onClick={()=>onBack()} style={{padding:"10px 0px"}}>Trở lại</p>
        </div>
        <p style={{marginTop:15}}>* Các món đã chọn</p>
        {renderItem()}
        <p>* Thông tin khác</p>
        <div style={{display:'flex',gap:15,alignItems:'center',marginTop:10}}>
          <p style={{fontWeight:'bold'}}>{table.Name} - </p>
          <Select
            placeholder="Chọn seat"
            onChange={onGenderChange}
            allowClear
          >
            <Option value="1">Seat 1</Option>
            <Option value="2">Seat 2</Option>
            <Option value="3">Seat 3</Option>
            <Option value="4">Seat 4</Option>
            <Option value="5">Seat 5</Option>
            <Option value="6">Seat 6</Option>
            <Option value="7">Seat 7</Option>
            <Option value="8">Seat 8</Option>
            <Option value="9">Seat 9</Option>
          </Select>
        </div>
        {error?.length > 0 && <p style={{color:'red'}}>{error}</p>}
      </div>
      {carts?.length > 0 && 
        <div style={{ position: "fixed", bottom: 0, width: "100%" }}>
        <div
          className="primary-bg"

          style={{
            // padding: "10px 20px",
            paddingLeft:'10px',
            boxShadow: "1px 3px 12px 1px rgba(208, 216, 243, 0.6)",
            display:'flex',justifyContent:'space-between',alignItems:'center'
          }}
        >
          <p>Món: {carts?.reduce((pre,currentValue)=>{
            return pre+=currentValue.quantity
          },0)}</p>
          <p>Tổng tiền: {
          Format_Currency(carts?.reduce((pre,currentValue)=>{
            return pre+= (currentValue.quantity*currentValue.giaBan)
          },0))  
          }</p>
          <div className="secondary-bg" style={{padding:'10px 30px'}} onClick={()=>submit()}>
          <p>Xác nhận</p>
          </div>
        
        </div>
      </div>
      }
    </div>
  );
}
