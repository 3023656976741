import axios from "axios";
import { post } from "./api_helper";
import axiosApi from "./api_helper";
export const TourService = {
  getDetail: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/Poker/get-giai-dau-detail", payload)
      .then((res) => res.data);
    // return await post("/Poker/DanhSachGiaiDau_ChiTietHIenThi",payload)
  },
  startGiaiDau: async (payload) => {
    return await axiosApi()
      .post("api/Poker/start-giai-dau", payload)
      .then((res) => res.data);
  },
  addGiaiDau: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/add-giai-dau", payload)
      .then((res) => res.data);
  },

  updateGiaiDau: async (payload) => {
    return await axiosApi()
      .post("api/poker/update-giai-dau", payload)
      .then((res) => res.data);
  },
  deleteGiaiDau: async (payload) => {
    return await axiosApi()
      .post("api/poker/delete-giai-dau", payload)
      .then((res) => res.data);
  },
  deleteCoCauGiaiThuong: async (payload) => {
    return await axiosApi()
      .post("api/poker/delete-co-cau-giai", payload)
      .then((res) => res.data);
  },
  getCoCauGiaiThuong: async (payload) => {
    return await axiosApi()
      .post("api/poker/get-co-cau-giai", payload)
      .then((res) => res.data);
  },

  getGiaiDauMau: async () => {
    return await axiosApi()
      .post("api/poker/get-giai-dau-mau")
      .then((res) => res.data);
  },

  getBlind: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/get-blind", payload)
      .then((res) => res.data);
  },
  addBlind: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/add-blind", payload)
      .then((res) => res.data);
  },
  removeBlind: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/remove-blind", payload)
      .then((res) => res.data);
  },
};
