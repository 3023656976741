import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect,useRef,useState } from "react";
import { HomeService } from "services/HomeService";
import { Button, Popconfirm, Table,Tooltip,notification } from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import { IoEyeSharp, IoSettingsOutline } from "react-icons/io5";
import { TourService } from "services/TourService";
import _ from 'lodash'
import Toolbar from "components/Toolbar";
import { SanPhamService } from "services/SanPhamService";
import { CardService } from "services/CardService";
import { Format_Datetime } from "layouts/ultis/common";
export default function Settings() {
  const [api, contextHolder] = notification.useNotification();

  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    InputSearch:""
  });
  const refDetail = useRef(null);
  const refSettingBlind = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useEffect(()=>{
    filter(filterCondition);
  },[])
  const filter =async (_filter) =>{
    setLoading(true);
    let _res = await CardService.getLichSuRaVao(_filter);
    if(_res.data) setData(_res.data);
    setLoading(false);
  }
  const onChangeFilter = (_filter) =>{
    filter(_filter);
    setFilterCondition(_filter);
  }
  const onAfterSubmit = ()=>{
    filter(filterCondition);
  }
  const columns = [
    {
      title: "Mã vạch",
      dataIndex: "MaVach",
    },
    {
      title: "Thời gian vào",
      dataIndex: "ThoiGianVao",
      render: (item) => Format_Datetime(item),
    },
    // {
    //   title: "Tên dịch vụ",
    //   dataIndex: "TenSP",
    // },
    // {
    //   title: "Loại dịch vụ",
    //   dataIndex: "TenLSP",
    // },
    {
      title: "Khách hàng",
      dataIndex: "TenKH",
    },
    {
      title: "Điện thoại",
      dataIndex: "DiDong",
    },
    // {
    //   title: "",
    //   fixed: "right",
    //   width: "50px",
    //   render: (item) => {
    //     return (
    //       <div>
    //         <Popconfirm
    //           title="Xoá sản phẩm"
    //           description="Bạn có chắc chắn muốn xoá sản phẩm?"
    //           okText="Đồng ý"
    //           cancelText="Không"
    //           onConfirm={() => remove(item.ID)}
    //         >
    //           <Tooltip placement="topRight" title="Xoá">
    //             <Button
    //               danger
    //               ghost
    //               icon={<CiTrash />}
    //             />
    //           </Tooltip>
    //         </Popconfirm>
    //       </div>
    //     );
    //   },
    // },
  ];
  const remove = async (value) => {
    let _res = await SanPhamService.deleteSanPham({ ID: value });
    if(_res.status === 2000){
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== value);
      setData(fil);
      openNotification(
        "success",
        "topRight",
        "Xoá sản phẩm thành công"
      );
    }else{
      openNotification("error", "topRight", _res?.message);
    }
  };
 
  return (
    <Box pt={{ base: "130px", md: "60px", xl: "60px" }}>
      <Toolbar
        left={() => (
          <Button type="primary" onClick={() => refDetail.current.show()}>
            Thêm
          </Button>
        )}
        right={() => (
          <FilterModal
            filterCondition={filterCondition}
            onChangeFilter={onChangeFilter}
          />
        )}
      />{" "}
      <Table
        style={{ height: 100 }}
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 250,
          // x: 1500,
        }}
        size="small"
      />
      {/* <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <DanhSachGiaiTable
          columnsData={columnsDataDevelopment}
          tableData={data}
          // onChangeDate={(p,v)=>onChangeDate(p,v)}
          // date={date}
        />
      </SimpleGrid> */}
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Box>
  );
}
