import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button, Col, DatePicker, Input, Popover, Row, Select } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { FaFilter } from "react-icons/fa";
const FilterModal = ({ ...props }) => {
  const { companies, filterCondition, onChangeFilter } = props;
  const [visible, setVisible] = useState(false);
  const handleOpenChange = (newOpen) => {
    setVisible(newOpen);
  };
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "ListMaCT":
        _filter[p] = v?.length > 0 ? ','+v.join(",")+',' : ',,';
        break;
      case "TuNgay":
        _filter[p] = new Date(v);
        break;
      case "DenNgay":
        _filter[p] = new Date(v);
        break;
      default:
        _filter[p]=v;
        break;
    }
    onChangeFilter(_filter);
  };
  return (
    <div style={{display:'flex'}}>
      <Input placeholder="Tìm kiếm" onChange={(e)=>applyChangeFilter("InputSearch",e.target.value)} />
      <Popover
        trigger="click"
        open={visible}
        onOpenChange={handleOpenChange}
        content={() => {
          return (
            <>
              {/* <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
                marginBottom: 20,
              }}
              fieldNames={{
                label: "TenCT",
                value: "ID",
              }}
              placeholder="Chọn công ty"
              defaultValue={["Tất cả"]}
              onChange={(v) => applyChangeFilter("ListMaCT", v)}
              options={companies}
            /> */}
              <DatePicker
                format="DD-MM-YYYY"
                style={{ width: "100%", marginBottom: 20 }}
                defaultValue={dayjs(filterCondition.TuNgay)}
                placeholder="Từ ngày"
                onChange={(v) => applyChangeFilter("TuNgay", v)}

                // locale={locale}
                // onChange={(v) => applyChangeFilter("TuNgay", v)}
              />
              <DatePicker
                format="DD-MM-YYYY"
                style={{ width: "100%" }}
                defaultValue={dayjs(filterCondition.DenNgay)}
                placeholder="Đến ngày "
                // locale={locale}
                onChange={(v) => applyChangeFilter("DenNgay", v)}
              />
            </>
          );
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Button  ghost>
            <FaFilter style={{color:'black'}} />
          </Button>
        </div>
      </Popover>
    </div>
  );
};
export default FilterModal;
