import { Format_Currency } from "layouts/ultis/common";
import React, { useEffect, useState ,useContext} from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { HomeService } from "services/HomeService";
import SignalrContext from "contexts/SignalrContext";
import AnimatedNumbers from "react-animated-numbers";

import _ from 'lodash'
export default function Jackpot() {
  const { hubConnection } = useContext(SignalrContext);
  let params  = useLocation();
  const [data,setData] = useState({})

  useEffect(()=>{
    HomeService.ManHinh.getJackpot().then((res)=>{
      setData(res.data)
    })
  },[])
  const [num, setNum] = React.useState(331231);

  useEffect(() => {
    if (hubConnection) {
      hubConnection.on("ActionChangeJackpot", async (response) => {
        console.log(response);
        setData((pre) => {
          let _pre = _.cloneDeep(pre);
          _pre.gtd = response.gtd;
          _pre.hienTai = response.hienTai;
          _pre.giaiThuong = response.giaiThuong;
          return _pre;
        });
      })
    }
  }, [hubConnection]);

  const renderScreen = () =>{
    return (
      <div
        className="container-screen1"
        style={{
          backgroundImage: `url(${data?.background?.replace(
            "https://quanlykinhdoanh.com.vn/httpdocs/Upload/CRM/BEEPOKER/",""
          )})`,
          justifyContent: "center",
        }}
      >
        <div>
          <p
            style={{
              fontSize: 150,
              color: "yellow",
              textAlign: "center",
              lineHeight: 0.9,
              fontWeight: "bold",
            }}
          >
            {/* {Format_Currency(data?.gtd)} */}
            <AnimatedNumbers
              includeComma
              locale="vi-VN"
              transitions={(index) => ({
                type: "spring",
                duration: index / 3,
              })}
              animateToNumber={data?.gtd?.toFixed(0)}
              // fontStyle={{
              //   fontSize: 40,
              //   color: "red",
              // }}
            />
          </p>
          {data?.hienTai > 0 && (
            <p
              style={{
                fontSize: 80,
                color: "yellow",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              {Format_Currency(data?.hienTai)}
            </p>
          )}
        </div>
        <div>
          {data?.giaiThuong?.map((item) => {
            return (
              <p style={{ fontSize: 40, color: "white", textAlign: "center" }}>
                {item.TenGiai}:{" "}
                {Format_Currency((item.PhanTram * data?.gtd) / 100)}
              </p>
            );
          })}
        </div>
      </div>
    );
  }
  return (
    renderScreen()
  );
}
