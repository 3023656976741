import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Button, Popconfirm, Table, Tooltip, notification } from "antd";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import { TourService } from "services/TourService";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { NhanVienService } from "services/NhanVienService";
export default function Index() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const refDetail = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useEffect(() => {
    filter();
  }, []);
  const filter = async () => {
    setLoading(true);
    let _res = await NhanVienService.getNhanVien();
    if (_res.data) setData(_res.data);
    setLoading(false);
  };

  const onAfterSubmit = () => {
    filter();
  };
  const columns = [
    {
      title: "Mã số",
      dataIndex: "MaSo",
    },
    {
      title: "Họ và tên",
      dataIndex: "HoTen",
    },
    {
      title: "Ngày sinh",
      dataIndex: "NgaySinh",
      render: (item) => Format_Date(item),
    },
    {
      title: "Địa chỉ",
      dataIndex: "DiaChi",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "Chi nhánh",
      dataIndex: "ChiNhanh",
    },
    {
      title: "Khoá",
      dataIndex: "Lock",
      render: (item) => item ? 'Có' : 'Không',
    },

    {
      title: "",
      fixed: "right",
      width: "160px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá nhân viên"
              description="Bạn có chắc chắn muốn xoá nhân viên này?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.MaNV)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await NhanVienService.deleteNhanVien({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaNV !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá nhân viên thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <Box pt={{ base: "130px", md: "60px", xl: "60px" }}>
      <Toolbar
        left={() => (
          <Button type="primary" onClick={() => refDetail.current.show()}>
            Thêm
          </Button>
        )}
      />
      <Table
        style={{ height: 100 }}
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 250,
          // x: 2500
        }}
        size="small"
      />
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Box>
  );
}
