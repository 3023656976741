import * as React from "react";

const Toolbar = (props, ref) => {
  const { left,right } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 5,
        background: "white",
        padding: 6,
        boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
        borderRadius:12
      }}
    >
      <div>
      {typeof left === "function" && left()}
      </div>
      {typeof right === "function" && right()}
    </div>
  );
};
export default Toolbar;
