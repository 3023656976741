import React, { useState,useEffect,createContext } from 'react';
import {  HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

const SignalrContext = createContext({name: "signalr"});
export const SignalrContextProvider = ({children,user}) => {
    const [hubConnection, setHubConnection] = useState(null);
    useEffect(()=>{
        try{(async () => {
          let connection = new HubConnectionBuilder()
            .withUrl(localStorage.getItem("url") + "Poker")
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();
          await connection.start();
          setHubConnection(connection);
          return () => connection.off();
        })();}
        catch{}
    },[])
    return <SignalrContext.Provider value={{hubConnection}}>
        {children}
    </SignalrContext.Provider>
  };
export default SignalrContext;