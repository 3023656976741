import axios from "axios"

const API_URL = "https://api-client.mybeeland.com/"
const axiosApi = (headers) => {
  return axios.create({
    baseURL: localStorage.getItem("url") ?? API_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      ...headers,
    },
  });
}
export default axiosApi;


// export async function get(url) {
//   return await axiosApi
//     .get(url, {
//       headers: {
//         Authorization:
//           "Bearer " + JSON.parse(localStorage.getItem("authUser"))?.acessToken,
//       },
//     })
//     .then(response => response.data)
// }
// export async function post(url, data) {
//   return await axiosApi
//     .post(
//       url,
//       { ...data },
//       {
//         headers: {
//           Authorization:
//             "Bearer " +
//             JSON.parse(localStorage.getItem("authUser"))?.acessToken,
//         },
//       }
//     )
//     .then(response => response.data)
// }