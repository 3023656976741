import axiosApi from "./api_helper";

export const SanPhamService = {
  getSanPham: async (payload) => {
    return await axiosApi()
      .post("api/poker/get-san-pham", payload)
      .then((res) => res.data);
  },
  addSanPham: async (payload) => {
    return await axiosApi()
      .post("api/poker/add-san-pham", payload)
      .then((res) => res.data);
  },
  updateSanPham: async (payload) => {
    return await axiosApi()
      .post("api/poker/update-san-pham", payload)
      .then((res) => res.data);
  },
  deleteSanPham: async (payload) => {
    return await axiosApi()
      .post("api/poker/delete-san-pham", payload)
      .then((res) => res.data);
  },
  getLSP: async (payload) => {
    return await axiosApi()
      .post("api/poker/get-loaisp", payload)
      .then((res) => res.data);
  },
  getDVT: async (payload) => {
    return await axiosApi()
      .post("api/poker/get-donvitinh", payload)
      .then((res) => res.data);
  },
};
