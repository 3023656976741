import axios from "axios";
import { post } from "./api_helper";
import axiosApi from "./api_helper";
export const JackpotService = {
  getPhieuThu: async (payload) => {
    return await axiosApi()
      .post("api/poker/get-phieu-thu", payload)
      .then((res) => res.data);
  },
  addPhieuThu: async (payload) => {
    return await axiosApi()
      .post("api/poker/add-phieu-thu", payload)
      .then((res) => res.data);
  },
  updatePhieuThu: async (payload) => {
    return await axiosApi()
      .post("api/poker/update-phieu-thu", payload)
      .then((res) => res.data);
  },
  deletePhieuThu: async (payload) => {
    return await axiosApi()
      .post("api/poker/delete-phieu-thu", payload)
      .then((res) => res.data);
  },

  getPhieuChi: async (payload) => {
    return await axiosApi()
      .post("api/poker/get-phieu-chi", payload)
      .then((res) => res.data);
  },

  addPhieuChi: async (payload) => {
    return await axiosApi()
      .post("api/poker/add-phieu-chi", payload)
      .then((res) => res.data);
  },
  updatePhieuChi: async (payload) => {
    return await axiosApi()
      .post("api/poker/update-phieu-chi", payload)
      .then((res) => res.data);
  },
  deletePhieuChi: async (payload) => {
    return await axiosApi()
      .post("api/poker/delete-phieu-chi", payload)
      .then((res) => res.data);
  },
  getJackpotDetail: async (payload) => {
    return await axiosApi()
      .post("api/poker/get-jackpot-detail", payload)
      .then((res) => res.data);
  },
  addUpdateJackpot: async (payload) => {
    return await axiosApi()
      .post("api/poker/add-update-jackpot", payload)
      .then((res) => res.data);
  },
};
